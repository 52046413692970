// All Summary Pages
export const SUMMARY_SECTION                      = 'summary';
export const FINAL_SUMMARY_SECTION                = 'final-summary';

// Application constants
export const APPLICATION_PURCHASE_FUNDS_SECTION   = 105;

// Personal Loan
export const PERSONAL_LOAN_GET_FICO_AND_ZIP_SECTION          = 100;
export const PERSONAL_LOAN_APP_BORROWER_DATA                 = 101;
export const PERSONAL_LOAN_GET_LOAN_AMOUNT_SECTION           = 102;
export const PERSONAL_LOAN_APP_CO_BORROWER_DATA              = 103;
export const PERSONAL_LOAN_APP_HOUSING                       = 104;
export const PERSONAL_LOAN_APP_HOMEOWNER                     = 105;
export const PERSONAL_LOAN_APP_RENTING                       = 106;
export const PERSONAL_LOAN_APP_RENT_FREE                     = 107;
export const PERSONAL_LOAN_APP_CO_BORROWER_HOUSING           = 108;
export const PERSONAL_LOAN_APP_INCOME_SOURCE                 = 109;
export const PERSONAL_LOAN_APP_EMPLOYED                      = 110;
export const PERSONAL_LOAN_APP_RETIRED                       = 111;
export const PERSONAL_LOAN_APP_EMPLOYEMENT_OTHER             = 112;
export const PERSONAL_LOAN_APP_CO_BORROWER_INCOME_SOURCE     = 113;
export const PERSONAL_LOAN_APP_CO_BORROWER_EMPLOYED          = 114;
export const PERSONAL_LOAN_APP_CO_BORROWER_RETIRED           = 115;
export const PERSONAL_LOAN_APP_CO_BORROWER_EMPLOYEMENT_OTHER = 116;
export const PERSONAL_LOAN_APP_CREDIT_AUTHORIZATION          = 117;
export const PERSONAL_LOAN_APP_CONGRATS                      = 118;
export const PERSONAL_LOAN_APP_FINAL_SECTION                 = 199;

export const PERSONAL_LOAN_LOAN_AMOUNT_QUESTION          = 1001;
export const PERSONAL_LOAN_CREDIT_REPORT_RESULT_QUESTION = 1059;
export const PERSONAL_LOAN_FINAL_QUESTION                = 9999;

// Overdraft Protection
export const OVERDRAFT_PROTECTION_GET_FICO_AND_ZIP_SECTION          = 101;
export const OVERDRAFT_PROTECTION_CHECKING                          = 102;
export const OVERDRAFT_PROTECTION_APP_BORROWER_DATA                 = 103;
export const OVERDRAFT_PROTECTION_GET_LOAN_AMOUNT_SECTION           = 104;
export const OVERDRAFT_PROTECTION_APP_CO_BORROWER_DATA              = 105;
export const OVERDRAFT_PROTECTION_APP_HOUSING                       = 106;
export const OVERDRAFT_PROTECTION_APP_HOMEOWNER                     = 107;
export const OVERDRAFT_PROTECTION_APP_RENTING                       = 108;
export const OVERDRAFT_PROTECTION_APP_RENT_FREE                     = 109;
export const OVERDRAFT_PROTECTION_APP_CO_BORROWER_HOUSING           = 110;
export const OVERDRAFT_PROTECTION_APP_INCOME_SOURCE                 = 111;
export const OVERDRAFT_PROTECTION_APP_EMPLOYED                      = 112;
export const OVERDRAFT_PROTECTION_APP_RETIRED                       = 113;
export const OVERDRAFT_PROTECTION_APP_EMPLOYEMENT_OTHER             = 114;
export const OVERDRAFT_PROTECTION_APP_CO_BORROWER_INCOME_SOURCE     = 115;
export const OVERDRAFT_PROTECTION_APP_CO_BORROWER_EMPLOYED          = 116;
export const OVERDRAFT_PROTECTION_APP_CO_BORROWER_RETIRED           = 117;
export const OVERDRAFT_PROTECTION_APP_CO_BORROWER_EMPLOYEMENT_OTHER = 118;
export const OVERDRAFT_PROTECTION_APP_CREDIT_AUTHORIZATION          = 119;
export const OVERDRAFT_PROTECTION_APP_CONGRATS                      = 120;
export const OVERDRAFT_PROTECTION_APP_FINAL_SECTION                 = 199;

export const OVERDRAFT_PROTECTION_LOAN_AMOUNT_QUESTION          = 1001;
export const OVERDRAFT_PROTECTION_CREDIT_REPORT_RESULT_QUESTION = 1059;
export const OVERDRAFT_PROTECTION_FINAL_QUESTION                = 9999;

// Closing Cost Form
export const CLOSING_COSTS_RATE_SELECTION = 'rates';
export const CLOSING_COSTS_COMPLETION     = 102;

// Insurance Quote Form
export const INSURANCE_QUOTE_CONSENT = 101;
export const INSURANCE_QUOTE_ADDITIONAL_QUESTIONS = 102;

// Auto Loan Sections
export const AUTO_LOAN_GET_PURCHASE_PLAN                   = 101;
export const AUTO_LOAN_SALVAGE_TITLE_QUESTION              = 102;
export const AUTO_LOAN_GET_FICO_AND_ZIP                    = 103;
export const AUTO_LOAN_GET_LOAN_INFORMATION                = 104;
export const AUTO_LOAN_GET_CONTACT_INFORMATION             = 105;
export const AUTO_LOAN_APP_CHOOSE_VEHICLE                  = 106;
export const AUTO_LOAN_APP_COBORROWER_PERSONAL_INFORMATION = 107;
export const AUTO_LOAN_APP_CURRENT_HOUSING_SITUATION       = 108;
export const AUTO_LOAN_APP_HOUSING_OWNER_INFORMATION       = 109;
export const AUTO_LOAN_APP_HOUSING_RENTER_INFORMATION      = 110;
export const AUTO_LOAN_APP_HOUSING_FREE_INFORMATION        = 111;
export const AUTO_LOAN_APP_COBORROWER_HOUSING_INFORMATION  = 112;
export const AUTO_LOAN_APP_BORROWER_INCOME_SOURCE          = 113;
export const AUTO_LOAN_APP_BORROWER_INCOME_EMPLOYED        = 114;
export const AUTO_LOAN_APP_BORROWER_INCOME_RETIRED         = 115;
export const AUTO_LOAN_APP_BORROWER_INCOME_OTHER           = 116;
export const AUTO_LOAN_APP_COBORROWER_INCOME_SOURCE        = 117;
export const AUTO_LOAN_APP_COBORROWER_INCOME_EMPLOYED      = 118;
export const AUTO_LOAN_APP_COBORROWER_INCOME_RETIRED       = 119;
export const AUTO_LOAN_APP_COBORROWER_INCOME_OTHER         = 120;
export const AUTO_LOAN_APP_CITIZENSHIP_AND_AUTHORIZATION   = 121;
export const AUTO_LOAN_APP_CREDIT_REPORT_RESULT            = 122;
export const AUTO_LOAN_CREDIT_LIFE_QUOTE                   = 123;
export const AUTO_LOAN_APP_FINAL                           = 124;

// Auto Loan Questions
export const AUTO_LOAN_CREDIT_REPORT_RESULT_QUESTION       = 1059;
export const AUTO_LOAN_FINAL_PAGE_QUESTION                 = 9998;

// Home Equity Turbo Sections
export const HOME_EQUITY_TURBO_PERSONAL_INFO              = 101;
export const HOME_EQUITY_TURBO_PROPERTY_COLLATERAL        = 102;
export const HOME_EQUITY_TURBO_PROPERTY_VERIFIED          = 103;
export const HOME_EQUITY_TURBO_HOME_VALUATION_ADJUST      = 104;
export const HOME_EQUITY_TURBO_CREDIT                     = 105;
export const HOME_EQUITY_TURBO_LOAN_PURPOSE               = 106;
export const HOME_EQUITY_TURBO_BORROW_AMOUNT              = 107;
export const HOME_EQUITY_TURBO_LOAN_AMOUNT                = 108;
export const HOME_EQUITY_TURBO_PERSONAL_INFO_TWO          = 109;
export const HOME_EQUITY_TURBO_CITIZENSHIP                = 110;
export const HOME_EQUITY_TURBO_TAX_INS_FEE                = 111;
export const HOME_EQUITY_TURBO_PRIMARY_HOME               = 112;
export const HOME_EQUITY_TURBO_COBORROWER                 = 113;
export const HOME_EQUITY_TURBO_COBORROWER_ADDRESS         = 114;
export const HOME_EQUITY_TURBO_CREDIT_REPORT_ONE          = 115;
export const HOME_EQUITY_TURBO_REAL_ESTATE_LIABILITIES    = 116;
export const HOME_EQUITY_TURBO_REO_PRIMARY_QUESTIONS      = 117;
export const HOME_EQUITY_TURBO_REO_SECONDARY_QUESTIONS    = 118;
export const HOME_EQUITY_TURBO_ADDITIONAL_PROPERTIES      = 119;
export const HOME_EQUITY_TURBO_AUTOMATIC_VOIE             = 120;
export const HOME_EQUITY_TURBO_COLLECT_SSN                = 121;
export const HOME_EQUITY_TURBO_VOIE_START_CONNECT         = 122;
export const HOME_EQUITY_TURBO_VOIE_REPORTS               = 123;
export const HOME_EQUITY_TURBO_EMPLOYMENT_TYPE            = 124;
export const HOME_EQUITY_TURBO_EMPLOYEE_INFO              = 125;
export const HOME_EQUITY_TURBO_RETIRED_INFO               = 126;
export const HOME_EQUITY_TURBO_COBORROWER_EMPLOYMENT_TYPE = 127;
export const HOME_EQUITY_TURBO_COBORROWER_EMPLOYEE_INFO   = 128;
export const HOME_EQUITY_TURBO_BORROWER_DECLARATION       = 129;
export const HOME_EQUITY_TURBO_COBORROWER_DECLARATION     = 130;
export const HOME_EQUITY_TURBO_COLLECT_SSN_FINAL          = 131;
export const HOME_EQUITY_TURBO_UPLOAD_DOCUMENTS           = 132;
export const HOME_EQUITY_MINIMUM_LOAN_STOP_GATE             = 133;

export const HOME_EQUITY_TURBO_COLLECT_SSN_QUESTION       = 1114;
export const HOME_EQUITY_TURBO_COLLECT_SSN_QUESTION_FINAL = 1115;

// Other Vehicle Loan Sections
export const OV_LOAN_GET_VEHICLE_TYPE                    = 101;
export const OV_LOAN_GET_LOAN_INFORMATION                = 102;
export const OV_LOAN_APP_PERSONAL_INFORMATION            = 103;
export const OV_LOAN_APP_COBORROWER_PERSONAL_INFORMATION = 104;
export const OV_LOAN_APP_CURRENT_HOUSING_SITUATION       = 105;
export const OV_LOAN_APP_HOUSING_OWNER_INFORMATION       = 106;
export const OV_LOAN_APP_HOUSING_RENTER_INFORMATION      = 107;
export const OV_LOAN_APP_HOUSING_FREE_INFORMATION        = 108;
export const OV_LOAN_APP_COBORROWER_HOUSING_INFORMATION  = 109;
export const OV_LOAN_APP_BORROWER_INCOME_SOURCE          = 110;
export const OV_LOAN_APP_BORROWER_INCOME_EMPLOYED        = 111;
export const OV_LOAN_APP_BORROWER_INCOME_RETIRED         = 112;
export const OV_LOAN_APP_BORROWER_INCOME_OTHER           = 113;
export const OV_LOAN_APP_COBORROWER_INCOME_SOURCE        = 114;
export const OV_LOAN_APP_COBORROWER_INCOME_EMPLOYED      = 115;
export const OV_LOAN_APP_COBORROWER_INCOME_RETIRED       = 116;
export const OV_LOAN_APP_COBORROWER_INCOME_OTHER         = 117;
export const OV_LOAN_APP_CITIZENSHIP_AND_AUTHORIZATION   = 118;
export const OV_LOAN_APP_FINAL_SECTION                   = 119;

export const OV_LOAN_FINAL_PAGE_QUESTION                 = 9998;

// Credit Card Sections
export const CREDIT_CARD_APP_DOB                             = 101;
export const CREDIT_CARD_APP_CC_TYPE_AND_PERSONAL_INFO       = 102;
export const CREDIT_CARD_APP_COBORROWER_PERSONAL_INFORMATION = 103;
export const CREDIT_CARD_APP_RELATIVE_PERSONAL_INFORMATION   = 104;
export const CREDIT_CARD_APP_CURRENT_HOUSING_SITUATION       = 105;
export const CREDIT_CARD_APP_HOUSING_OWNER_INFORMATION       = 106;
export const CREDIT_CARD_APP_HOUSING_RENTER_INFORMATION      = 107;
export const CREDIT_CARD_APP_HOUSING_FREE_INFORMATION        = 108;
export const CREDIT_CARD_APP_COBORROWER_HOUSING_INFORMATION  = 109;
export const CREDIT_CARD_APP_RELATIVE_HOUSING_INFORMATION    = 110;
export const CREDIT_CARD_APP_BORROWER_INCOME_SOURCE          = 111;
export const CREDIT_CARD_APP_BORROWER_INCOME_EMPLOYED        = 112;
export const CREDIT_CARD_APP_BORROWER_INCOME_RETIRED         = 113;
export const CREDIT_CARD_APP_BORROWER_INCOME_OTHER           = 114;
export const CREDIT_CARD_APP_LIMIT                           = 115;
export const CREDIT_CARD_APP_COBORROWER_INCOME_SOURCE        = 116;
export const CREDIT_CARD_APP_COBORROWER_INCOME_EMPLOYED      = 117;
export const CREDIT_CARD_APP_COBORROWER_INCOME_RETIRED       = 118;
export const CREDIT_CARD_APP_COBORROWER_INCOME_OTHER         = 119;
export const CREDIT_CARD_APP_SSN                             = 120;
export const CREDIT_CARD_APP_FINAL                           = 121;

// Credit Card Question
export const CREDIT_CARD_FINAL_PAGE_QUESTION                 = 9999;

// Home Equity Section
export const HOME_EQUITY_BRANCH_INFORMATION                    = 100;
export const HOME_EQUITY_LOAN_PURPOSE                          = 101;
export const HOME_EQUITY_PERSONAL_INFORMATION                  = 102;
export const HOME_EQUITY_PROPERTY_ADDRESS                      = 103;
export const HOME_EQUITY_COBORROWER_PERSONAL_INFORMATION       = 104;
export const HOME_EQUITY_COBORROWER_CITIZEN_TYPE               = 105;
export const HOME_EQUITY_MARITAL_AND_CREDIT_SCORE              = 106;
export const HOME_EQUITY_CITIZEN_TYPE                          = 107;
export const HOME_EQUITY_PROPERTY_INFO                         = 108;
export const HOME_EQUITY_LOAN_AMOUNT                           = 109;
export const HOME_EQUITY_TAXES_INSURANCE_HOA                   = 110;
export const HOME_EQUITY_ADDITIONAL_PROPERTIES                 = 111;
export const HOME_EQUITY_EMPLOYMENT_TYPE                       = 112;
export const HOME_EQUITY_EMPLOYEE_INFO                         = 113;
export const HOME_EQUITY_RETIRED_INFO                          = 114;
export const HOME_EQUITY_COBORROWER_EMPLOYMENT_TYPE            = 115;
export const HOME_EQUITY_COBORROWER_EMPLOYEE_INFO              = 116;
export const HOME_EQUITY_BORROWER_QUESTIONS                    = 117;
export const HOME_EQUITY_COBORROWER_QUESTIONS                  = 118;
export const HOME_EQUITY_SSN                                   = 119;
export const HOME_EQUITY_LAST_STEP                             = 120;
export const HOME_EQUITY_CONFIRMATION                          = 121;
// Heloc long form constants
export const LF_HELOC_STATE_NOT_COVERED_STOP_GATE              = 122;
export const LF_HELOC_MINIMUM_LOAN_STOP_GATE                   = 123;

// CONSTRUCTION Loan Sections
export const CONSTRUCTION_GET_PERSONAL_INFORMATION                              = 101;
export const CONSTRUCTION_GET_CONSTRUCTION_INFORMATION                          = 102;
export const CONSTRUCTION_GET_CONSTRUCTION_LOCATION_INFORMATION                 = 103;
export const CONSTRUCTION_GET_AVAILABLE_FUNDS                                   = 104;
export const CONSTRUCTION_GET_FICO_MARRIAGE_AND_DEPENDENTS                      = 105;
export const CONSTRUCTION_GET_CITIZEN_AND_MILITARY                              = 106;
export const CONSTRUCTION_GET_COBORROWER_PERSONAL_INFORMATION                   = 107;
export const CONSTRUCTION_GET_COBORROWER_HOUSING_INFORMATION                    = 108;
export const CONSTRUCTION_GET_COBORROWER_CITIZEN_AND_MILITARY_INFORMATION       = 109;
export const CONSTRUCTION_GET_PURCHASE_PLAN_CURRENT_HOUSING_SITUATION           = 110;
export const CONSTRUCTION_GET_SELL_CURRENT_HOUSE                                = 111;
export const CONSTRUCTION_GET_HOUSING_OWNER_INFORMATION                         = 112;
export const CONSTRUCTION_GET_HOUSING_RENTER_INFORMATION                        = 113;
export const CONSTRUCTION_GET_HOUSING_FREE_INFORMATION                          = 114;
export const CONSTRUCTION_GET_ADDITIONAL_PROPERTIES                             = 115;
export const CONSTRUCTION_GET_BORROWER_INCOME_SOURCE                            = 116;
export const CONSTRUCTION_GET_BORROWER_INCOME_EMPLOYED_OR_SELF                  = 117;
export const CONSTRUCTION_GET_BORROWER_INCOME_RETIRED_OR_OTHER                  = 118;
export const CONSTRUCTION_GET_COBORROWER_INCOME_SOURCE                          = 119;
export const CONSTRUCTION_GET_COBORROWER_INCOME_EMPLOYED_OR_SELF                = 120;
export const CONSTRUCTION_GET_COBORROWER_INCOME_RETIRED_OR_OTHER                = 121;
export const CONSTRUCTION_GET_BORROWER_QUESTIONS                                = 122;
export const CONSTRUCTION_GET_COBORROWER_QUESTIONS                              = 123;
export const CONSTRUCTION_GET_BORROWER_SSN                                      = 124;
export const CONSTRUCTION_LAST_STEP                                             = 125;
export const CONSTRUCTION_CONFIRMATION                                          = 126;

// Reverse Mortgage Sections
export const REVERSE_LOAN_GOAL                       = 101;
export const REVERSE_PERSONAL_INFO                   = 102;
export const REVERSE_COBORROWER_PERSONAL_INFORMATION = 103;
export const REVERSE_PURCHASE_PROPERTY_INFO          = 104;
export const REVERSE_PROPERTY_INFO                   = 105;
export const REVERSE_MORTGAGE_INFO                   = 106;
export const REVERSE_CURRENT_RESIDENCE               = 107;
export const REVERSE_CITIZEN_AND_MARRIAGE            = 108;
export const REVERSE_COBORROWER_CITIZEN_AND_MARRIAGE = 109;
export const REVERSE_PROPERTY_TYPE_AND_USE           = 110;
export const REVERSE_PROPERTY_TITLE_INFO             = 111;
export const REVERSE_PROPERTY_TAX_INS_FEES           = 112;
export const REVERSE_INCOME_AND_ASSETS               = 113;
export const REVERSE_BORROWER_QUESTIONS              = 114;
export const REVERSE_COBORROWER_QUESTIONS            = 115;
export const REVERSE_SSN                             = 116;
export const REVERSE_CONFIRMATION                    = 117;
export const REVERSE_ADVISOR_STOP_GATE               = 118;

// Commercial Purchase Sections
export const COMMERCIAL_PURCHASE_CHOSEN_PROPERTY            = 101;
export const COMMERCIAL_PURCHASE_AGREEMENT                  = 102;
export const COMMERCIAL_PURCHASE_PROPERTY_INFO              = 103;
export const COMMERCIAL_PURCHASE_AVAILABLE_FUNDS            = 104;
export const COMMERCIAL_PURCHASE_ASSETS                     = 105;
export const COMMERCIAL_PURCHASE_HOUSING                    = 106;
export const COMMERCIAL_PURCHASE_PROPERTY_DETAILS           = 107;
export const COMMERCIAL_PURCHASE_EXISTING_CUSTOMER          = 108;
export const COMMERCIAL_PURCHASE_BUSINESS_INFO              = 109;
export const COMMERCIAL_PURCHASE_BUSINESS_FINANCIALS        = 110;
export const COMMERCIAL_PURCHASE_BUSINESS_OWNERS            = 111;
export const COMMERCIAL_PURCHASE_BUSINESS_MANAGEMENT        = 112;
export const COMMERCIAL_PURCHASE_LOAN_HOLDER                = 113;
export const COMMERCIAL_PURCHASE_PERSONAL_INFO              = 114;
export const COMMERCIAL_PURCHASE_BORROWER_LIVING_SITUATION  = 115;
export const COMMERCIAL_PURCHASE_BORROWER_HOME_INFO         = 116;
export const COMMERCIAL_PURCHASE_BORROWER_RENTER_INFO       = 117;
export const COMMERCIAL_PURCHASE_BORROWER_INCOME_SOURCE     = 118;
export const COMMERCIAL_PURCHASE_BORROWER_EMPLOYMENT        = 119;
export const COMMERCIAL_PURCHASE_BORROWER_RETIREMENT        = 120;
export const COMMERCIAL_PURCHASE_EXISTING_CUSTOMER_INFO     = 121;
export const COMMERCIAL_PURCHASE_BORROWER_QUESTIONS         = 122;
export const COMMERCIAL_PURCHASE_FINAL                      = 123;

// Commercial Refinance Sections
export const COMMERCIAL_REFINANCE_LOAN_PURPOSE               = 101;
export const COMMERCIAL_REFINANCE_PROPERTY_INFO              = 102;
export const COMMERCIAL_REFINANCE_PROPERTY_COST              = 103;
export const COMMERCIAL_REFINANCE_PROPERTY_DETAILS           = 104;
export const COMMERCIAL_REFINANCE_EXISTING_CUSTOMER          = 105;
export const COMMERCIAL_REFINANCE_BUSINESS_INFO              = 106;
export const COMMERCIAL_REFINANCE_BUSINESS_FINANCIALS        = 107;
export const COMMERCIAL_REFINANCE_BUSINESS_OWNERS            = 108;
export const COMMERCIAL_REFINANCE_BUSINESS_MANAGEMENT        = 109;
export const COMMERCIAL_REFINANCE_LOAN_HOLDER                = 110;
export const COMMERCIAL_REFINANCE_PERSONAL_INFO              = 111;
export const COMMERCIAL_REFINANCE_BORROWER_LIVING_SITUATION  = 112;
export const COMMERCIAL_REFINANCE_HOUSING_INFO               = 113;
export const COMMERCIAL_REFINANCE_BORROWER_RENTER_INFO       = 114;
export const COMMERCIAL_REFINANCE_BORROWER_INCOME_SOURCE     = 115;
export const COMMERCIAL_REFINANCE_BORROWER_EMPLOYMENT        = 116;
export const COMMERCIAL_REFINANCE_BORROWER_RETIREMENT        = 117;
export const COMMERCIAL_REFINANCE_EXISTING_CUSTOMER_INFO     = 118;
export const COMMERCIAL_REFINANCE_BORROWER_QUESTIONS         = 119;
export const COMMERCIAL_REFINANCE_FINAL                      = 120;

export const AUTO_PREQUAL_PERSONAL_INFO                    = 101;
export const AUTO_PREQUAL_BUYING_INFO                      = 102;
export const AUTO_PREQUAL_PURCHASE_INFO                    = 103;
export const AUTO_PREQUAL_ANNUAL_INFO                      = 104;
export const AUTO_PREQUAL_LIVING_PROPERTY                  = 105;
export const AUTO_PREQUAL_COBORROWER                       = 106;
export const AUTO_PREQUAL_VETERAN                          = 107;
export const AUTO_PREQUAL_CREDIT_AUTHORIZATION             = 108;
export const AUTO_PREQUAL_FINANCIAL_HISTORY                = 109;
export const AUTO_PREQUAL_REAL_ESTATE_LIABILITIES          = 110;
export const AUTO_PREQUAL_EMPLOYMENT_TYPE                  = 111;
export const AUTO_PREQUAL_EMPLOYMENT_INFO                  = 112;
export const AUTO_PREQUAL_COBORROWER_EMPLOYMENT_TYPE       = 113;
export const AUTO_PREQUAL_COBORROWER_EMPLOYMENT_INFO       = 114;
export const AUTO_PREQUAL_PREQUAL_AMOUNT                   = 115;
export const AUTO_PREQUAL_SUMMARY                          = 116;

export const AUTO_PREQUAL_RENEW_WELCOME_BACK               = 101;
export const AUTO_PREQUAL_RENEW_HOME_VALUE_DOWN_PAYMENT    = 102;
export const AUTO_PREQUAL_RENEW_CREDIT_AUTHORIZATON        = 103;
export const AUTO_PREQUAL_RENEW_FINANCIAL_HISTORTY         = 104;
export const AUTO_PREQUAL_RENEW_REAL_ESTATE_LIABILITIES    = 105;
export const AUTO_PREQUAL_RENEW_EMPLOYMENT_INFO            = 106;
export const AUTO_PREQUAL_RENEW_COBORROWER_EMPLOYMENT_INFO = 107;
export const AUTO_PREQUAL_RENEW_AMOUNT_INFO                = 108;
export const AUTO_PREQUAL_RENEW_SUMMARY                    = 109;

// Credit Booster Sections
export const CREDIT_BOOSTER_APP_GENERAL_INFO               = 101;
export const CREDIT_BOOSTER_APP_PERSONAL_INFORMATION       = 102;
export const CREDIT_BOOSTER_APP_CURRENT_HOUSING_SITUATION  = 103;
export const CREDIT_BOOSTER_APP_INCOME_AND_EMPLOYMENT      = 104;
export const CREDIT_BOOSTER_APP_ACCOUNT_INFO               = 105;
export const CREDIT_BOOSTER_APP_LOAN_SELECTION             = 106;
export const CREDIT_BOOSTER_APP_LOAN_AMOUNT                = 107;
export const CREDIT_BOOSTER_APP_CONFIRMATION               = 108;

// Commercial Equipment or Inventory
export const COMMERCIAL_EQUIPMENT_TYPE                       = 101;
export const COMMERCIAL_EQUIPMENT_LOAN_INFO                  = 102;
export const COMMERCIAL_EQUIPMENT_EXISTING_CUSTOMER          = 103;
export const COMMERCIAL_EQUIPMENT_BUSINESS_INFO              = 104;
export const COMMERCIAL_EQUIPMENT_BUSINESS_FINANCIALS        = 105;
export const COMMERCIAL_EQUIPMENT_BUSINESS_OWNERS            = 106;
export const COMMERCIAL_EQUIPMENT_BUSINESS_MANAGEMENT        = 107;
export const COMMERCIAL_EQUIPMENT_LOAN_HOLDER                = 108;
export const COMMERCIAL_EQUIPMENT_PERSONAL_INFO              = 109;
export const COMMERCIAL_EQUIPMENT_BORROWER_LIVING_SITUATION  = 110;
export const COMMERCIAL_EQUIPMENT_BORROWER_HOME_INFO         = 111;
export const COMMERCIAL_EQUIPMENT_BORROWER_RENTER_INFO       = 112;
export const COMMERCIAL_EQUIPMENT_BORROWER_INCOME_SOURCE     = 113;
export const COMMERCIAL_EQUIPMENT_BORROWER_EMPLOYMENT        = 114;
export const COMMERCIAL_EQUIPMENT_BORROWER_RETIREMENT        = 115;
export const COMMERCIAL_EQUIPMENT_EXISTING_CUSTOMER_INFO     = 116;
export const COMMERCIAL_EQUIPMENT_BORROWER_QUESTIONS         = 117;
export const COMMERCIAL_EQUIPMENT_FINAL                      = 118;

// Commercial Equipment or Inventory Questions
export const COMMERCIAL_INVENTORY_EXPECTED_COSTS             = 9000;
export const COMMERCIAL_EQUIPMENT_EXPECTED_COSTS             = 9001;
export const COMMERCIAL_EQUIPMENT_DESCRIPTION                = 9002;
export const COMMERCIAL_INVENTORY_DESCRIPTION                = 9003;
export const COMMERCIAL_EQUIPMENT_DOWN_PAYMENT               = 9004;
export const COMMERCIAL_EQUIPMENT_BUSINESS_NEED              = 9005;
export const COMMERCIAL_INVENTORY_BUSINESS_NEED              = 9006;
export const COMMERCIAL_INVENTORY_SHELF_LIFE                 = 9007;
export const COMMERCIAL_EQUIPMENT_LIFE_EXPECTANCY            = 9008;
export const COMMERCIAL_EQUIPMENT_PURCHASE_TIMING            = 9009;

// Commercial Vehicle or Inventory
export const COMMERCIAL_VEHICLE_LOAN_INFO                  = 101;
export const COMMERCIAL_VEHICLE_EXISTING_CUSTOMER          = 102;
export const COMMERCIAL_VEHICLE_BUSINESS_INFO              = 103;
export const COMMERCIAL_VEHICLE_BUSINESS_FINANCIALS        = 104;
export const COMMERCIAL_VEHICLE_BUSINESS_OWNERS            = 105;
export const COMMERCIAL_VEHICLE_BUSINESS_MANAGEMENT        = 106;
export const COMMERCIAL_VEHICLE_LOAN_HOLDER                = 107;
export const COMMERCIAL_VEHICLE_PERSONAL_INFO              = 108;
export const COMMERCIAL_VEHICLE_BORROWER_LIVING_SITUATION  = 109;
export const COMMERCIAL_VEHICLE_BORROWER_HOME_INFO         = 110;
export const COMMERCIAL_VEHICLE_BORROWER_RENTER_INFO       = 111;
export const COMMERCIAL_VEHICLE_BORROWER_INCOME_SOURCE     = 112;
export const COMMERCIAL_VEHICLE_BORROWER_EMPLOYMENT        = 113;
export const COMMERCIAL_VEHICLE_BORROWER_RETIREMENT        = 114;
export const COMMERCIAL_VEHICLE_EXISTING_CUSTOMER_INFO     = 115;
export const COMMERCIAL_VEHICLE_BORROWER_QUESTIONS         = 116;
export const COMMERCIAL_VEHICLE_FINAL                      = 117;

// Commercial Bridge Loan or Line of Credit
export const COMMERCIAL_BRIDGE_LOAN_TYPE                       = 101;
export const COMMERCIAL_BRIDGE_LOAN_LOAN_INFO                  = 102;
export const COMMERCIAL_BRIDGE_LOAN_EXISTING_CUSTOMER          = 103;
export const COMMERCIAL_BRIDGE_LOAN_BUSINESS_INFO              = 104;
export const COMMERCIAL_BRIDGE_LOAN_BUSINESS_FINANCIALS        = 105;
export const COMMERCIAL_BRIDGE_LOAN_BUSINESS_OWNERS            = 106;
export const COMMERCIAL_BRIDGE_LOAN_BUSINESS_MANAGEMENT        = 107;
export const COMMERCIAL_BRIDGE_LOAN_HOLDER                     = 108;
export const COMMERCIAL_BRIDGE_LOAN_PERSONAL_INFO              = 109;
export const COMMERCIAL_BRIDGE_LOAN_BORROWER_LIVING_SITUATION  = 110;
export const COMMERCIAL_BRIDGE_LOAN_BORROWER_HOME_INFO         = 111;
export const COMMERCIAL_BRIDGE_LOAN_BORROWER_RENTER_INFO       = 112;
export const COMMERCIAL_BRIDGE_LOAN_BORROWER_INCOME_SOURCE     = 113;
export const COMMERCIAL_BRIDGE_LOAN_BORROWER_EMPLOYMENT        = 114;
export const COMMERCIAL_BRIDGE_LOAN_BORROWER_RETIREMENT        = 115;
export const COMMERCIAL_BRIDGE_LOAN_EXISTING_CUSTOMER_INFO     = 116;
export const COMMERCIAL_BRIDGE_LOAN_BORROWER_QUESTIONS         = 117;
export const COMMERCIAL_BRIDGE_LOAN_FINAL                      = 118;

// Financial Statement Sections
export const FINANCIAL_STATEMENT_APP_PERSONAL_INFO                   = 101;
export const FINANCIAL_STATEMENT_APP_HOUSING_INFO                    = 102;
export const FINANCIAL_STATEMENT_APP_ASSETS_STOCK_AND_BONDS          = 103;
export const FINANCIAL_STATEMENT_APP_ASSETS_RETIREMENT               = 104;
export const FINANCIAL_STATEMENT_APP_ASSETS_NOTES_AND_CONTRACTS      = 105;
export const FINANCIAL_STATEMENT_APP_ASSETS_PERSONAL_PROPERTY        = 106;
export const FINANCIAL_STATEMENT_APP_ASSETS_REAL_ESTATE              = 107;
export const FINANCIAL_STATEMENT_APP_LIABILITIES_CREDIT_CARDS        = 108;
export const FINANCIAL_STATEMENT_APP_LIABILITIES_MORTGAGES           = 109;
export const FINANCIAL_STATEMENT_APP_LIABILITIES_LOANS_PAYABLE       = 110;
export const FINANCIAL_STATEMENT_APP_LIABILITIES_TAXES_PAYABLE       = 111;
export const FINANCIAL_STATEMENT_APP_FINAL                           = 112;

export const FINANCIAL_STATEMENT_FINAL_PAGE_QUESTION                 = 9999;

// Commercial Renew Sections
export const COMMERCIAL_RENEW_INFO_SUMMARY               = 101;

// Commercial Sections
export const COMMERCIAL_LOAN_TYPE                                 = 101;

// Short Form Home Equity
export const SF_HOME_EQUITY_PROPERTY_INFORMATION                  = 101;
export const SF_HOME_EQUITY_PROPERTY_ADDRESS                      = 102;
export const SF_HOME_EQUITY_CREDIT_SCORE                          = 103;
export const SF_HOME_EQUITY_LOAN_PURPOSE                          = 104;
export const SF_HOME_EQUITY_LOAN_AMOUNT                           = 105;
export const SF_HOME_EQUITY_PERSONAL_INFORMATION                  = 106;
// Error Pages
export const SF_HOME_EQUITY_STATE_NOT_COVERED                     = 107;
export const SF_HOME_EQUITY_MIN_LOAN_STOP_GATE                    = 108;

// Small Business Line Of Credit Sections
export const SB_LINE_OF_CREDIT_LOAN_INFO                  = 101;
export const SB_LINE_OF_CREDIT_BUSINESS_INFO              = 102;
export const SB_LINE_OF_CREDIT_OWNER_INFO                 = 103;
export const SB_LINE_OF_CREDIT_FINANCIAL_INFO             = 104;
export const SB_LINE_OF_CREDIT_SSN                        = 105;
export const SB_LINE_OF_CREDIT_FINAL                      = 106;

// Small Business Term Sections
export const SB_TERM_LOAN_INFO                            = 101;
export const SB_TERM_BUSINESS_INFO                        = 102;
export const SB_TERM_OWNER_INFO                           = 103;
export const SB_TERM_FINANCIAL_INFO                       = 104;
export const SB_TERM_SSN                                  = 105;
export const SB_TERM_FINAL                                = 106;

// LAND Loan Sections
export const LAND_GET_PERSONAL_INFORMATION                        = 101;
export const LAND_GET_GENERAL_INFO                                = 102;
export const LAND_GET_VALUE                                       = 103;
export const LAND_GET_LOCATION                                    = 104;
export const LAND_GET_AVAILABLE_FUNDS                             = 105;
export const LAND_DOWNPAYMENT_INFO                                = 106;
export const LAND_GET_FICO_MARRIAGE_AND_DEPENDENTS                = 107;
export const LAND_GET_CITIZEN_AND_MILITARY                        = 108;
export const LAND_GET_COBORROWER_PERSONAL_INFORMATION             = 109;
export const LAND_GET_COBORROWER_HOUSING_INFORMATION              = 110;
export const LAND_GET_COBORROWER_CITIZEN_AND_MILITARY_INFORMATION = 111;
export const LAND_GET_CURRENT_HOUSING_SITUATION                   = 112;
export const LAND_GET_SELL_CURRENT_HOUSE                          = 113;
export const LAND_GET_HOUSING_OWNER_INFORMATION                   = 114;
export const LAND_GET_HOUSING_RENTER_INFORMATION                  = 115;
export const LAND_GET_HOUSING_FREE_INFORMATION                    = 116;
export const LAND_GET_ADDITIONAL_PROPERTIES                       = 117;
export const LAND_GET_BORROWER_INCOME_SOURCE                      = 118;
export const LAND_GET_BORROWER_INCOME_EMPLOYED_OR_SELF            = 119;
export const LAND_GET_BORROWER_INCOME_RETIRED_OR_OTHER            = 120;
export const LAND_GET_COBORROWER_INCOME_SOURCE                    = 121;
export const LAND_GET_COBORROWER_INCOME_EMPLOYED_OR_SELF          = 122;
export const LAND_GET_COBORROWER_INCOME_RETIRED_OR_OTHER          = 123;
export const LAND_GET_BORROWER_QUESTIONS                          = 124;
export const LAND_GET_COBORROWER_QUESTIONS                        = 125;
export const LAND_GET_BORROWER_SSN                                = 126;
export const LAND_LAST_STEP                                       = 127;
export const LAND_CONFIRMATION                                    = 128;

// LF Purchase application
export const LF_PURCHASE_GET_PERSONAL_INFORMATION                        = 101;
export const LF_PURCHASE_GET_AGREEMENT_PREQUAL                           = 102;
export const LF_PURCHASE_GET_VALUE                                       = 103;
export const LF_PURCHASE_GET_LOAN_TYPE                                     = 104;
export const LF_PURCHASE_GET_LOCATION                                    = 105;
export const LF_PURCHASE_GET_AVAILABLE_FUNDS                             = 106;
export const LF_PURCHASE_DOWNPAYMENT_INFO                                = 107;
export const LF_PURCHASE_GET_FICO_MARRIAGE_AND_DEPENDENTS                = 108;
export const LF_PURCHASE_GET_CITIZEN_AND_MILITARY                        = 109;
export const LF_PURCHASE_GET_COBORROWER_PERSONAL_INFORMATION             = 110;
export const LF_PURCHASE_GET_COBORROWER_HOUSING_INFORMATION              = 111;
export const LF_PURCHASE_GET_COBORROWER_CITIZEN_AND_MILITARY_INFORMATION = 112;
export const LF_PURCHASE_GET_CURRENT_HOUSING_SITUATION                   = 113;
export const LF_PURCHASE_GET_SELL_CURRENT_HOUSE                          = 114;
export const LF_PURCHASE_GET_HOUSING_OWNER_INFORMATION                   = 115;
export const LF_PURCHASE_GET_HOUSING_RENTER_INFORMATION                  = 116;
export const LF_PURCHASE_GET_HOUSING_FREE_INFORMATION                    = 117;
export const LF_PURCHASE_GET_ADDITIONAL_PROPERTIES                       = 118;
export const LF_PURCHASE_GET_BORROWER_INCOME_SOURCE                      = 119;
export const LF_PURCHASE_GET_BORROWER_INCOME_EMPLOYED_OR_SELF            = 120;
export const LF_PURCHASE_GET_BORROWER_INCOME_RETIRED_OR_OTHER            = 121;
export const LF_PURCHASE_GET_COBORROWER_INCOME_SOURCE                    = 122;
export const LF_PURCHASE_GET_COBORROWER_INCOME_EMPLOYED_OR_SELF          = 123;
export const LF_PURCHASE_GET_COBORROWER_INCOME_RETIRED_OR_OTHER          = 124;
export const LF_PURCHASE_GET_BORROWER_QUESTIONS                          = 125;
export const LF_PURCHASE_GET_COBORROWER_QUESTIONS                        = 126;
export const LF_PURCHASE_GET_BORROWER_SSN                                = 127;
export const LF_PURCHASE_LAST_STEP                                       = 128;
export const LF_PURCHASE_CONFIRMATION                                    = 129;

// LF Refinance application
export const LF_REFINANCE_GET_LOAN_PURPOSE                                = 101;
export const LF_REFINANCE_GET_LOAN_TYPE                                     = 102;
export const LF_REFINANCE_GET_PERSONAL_INFO                               = 103;
export const LF_REFINANCE_GET_PROPERTY_LOCATION                           = 104;
export const LF_REFINANCE_GET_PROPERTY_INFO                               = 105;
export const LF_REFINANCE_GET_PROPERTY_INFO_2                             = 106;
export const LF_REFINANCE_GET_PRIMARY_HOME_LOCATION                       = 107;
export const LF_REFINANCE_ADDITIONAL_PROPERTIES                           = 108;
export const LF_REFINANCE_GET_FICO_MARRIAGE_AND_DEPENDENTS                = 109;
export const LF_REFINANCE_GET_CITIZEN_AND_MILITARY                        = 110;
export const LF_REFINANCE_GET_COBORROWER_PERSONAL_INFORMATION             = 111;
export const LF_REFINANCE_GET_COBORROWER_HOUSING_INFORMATION              = 112;
export const LF_REFINANCE_GET_COBORROWER_CITIZEN_AND_MILITARY_INFORMATION = 113;
export const LF_REFINANCE_GET_BORROWER_INCOME_SOURCE                      = 114;
export const LF_REFINANCE_GET_BORROWER_INCOME_EMPLOYED_OR_SELF            = 115;
export const LF_REFINANCE_GET_BORROWER_INCOME_RETIRED_OR_OTHER            = 116;
export const LF_REFINANCE_GET_COBORROWER_INCOME_SOURCE                    = 117;
export const LF_REFINANCE_GET_COBORROWER_INCOME_EMPLOYED_OR_SELF          = 118;
export const LF_REFINANCE_GET_COBORROWER_INCOME_RETIRED_OR_OTHER          = 119;
export const LF_REFINANCE_GET_ASSETS                                      = 120;
export const LF_REFINANCE_GET_BORROWER_QUESTIONS                          = 121;
export const LF_REFINANCE_GET_COBORROWER_QUESTIONS                        = 122;
export const LF_REFINANCE_GET_BORROWER_SSN                                = 123;
export const LF_REFINANCE_LAST_STEP                                       = 124;
export const LF_REFINANCE_CONFIRMATION                                    = 125;

// Refinance Turbo Application
export const REFINANCE_TURBO_LOAN_PURPOSE               = 101;
export const REFINANCE_TURBO_PROPERTY_TYPE              = 102;
export const REFINANCE_TURBO_ESTIMATED_CREDIT           = 103;
export const REFINANCE_TURBO_PROPERTY_INFO              = 104;
export const REFINANCE_TURBO_USPS_VERIFICATION          = 105;
export const REFINANCE_TURBO_PROPERTY_VALUE_RANGE       = 106;
export const REFINANCE_TURBO_CASH_AT_CLOSING            = 107;
export const REFINANCE_TURBO_CURRENT_LOAN_INFO          = 108;
export const REFINANCE_TURBO_PERSONAL_INFO              = 109;
export const REFINANCE_TURBO_OPTIMAL_SUMMARY            = 'optimal-summary';
export const REFINANCE_TURBO_SUMMARY                    = 'summary';
export const REFINANCE_TURBO_ANNUAL_COSTS               = 110;
export const REFINANCE_TURBO_PRIMARY_HOME               = 111;
export const REFINANCE_TURBO_ADDITIONAL_PROPERTIES      = 112;
export const REFINANCE_TURBO_MARITAL_STATUS_DEPENDENTS  = 113;
export const REFINANCE_TURBO_CITIZENSHIP_MILITARY       = 114;
export const REFINANCE_TURBO_COBORROWER_PERSONAL_INFO   = 115;
export const REFINANCE_TURBO_COBORROWER_HOUSING_INFO    = 116;
export const REFINANCE_TURBO_COBORROWER_CITIZENSHIP     = 117;
export const REFINANCE_TURBO_CREDIT_AUTHORIZATION       = 118;
export const REFINANCE_TURBO_FINAL_SUMMARY              = 'final-summary';
export const REFINANCE_TURBO_REAL_ESTATE_LIABILITIES    = 119;
export const REFINANCE_TURBO_CASH_AT_CLOSING_FINAL      = 120;
export const REFINANCE_TURBO_AUTOMATIC_VOIE             = 121;
export const REFINANCE_TURBO_COLLECT_SSN                = 122;
export const REFINANCE_TURBO_VOIE_START_CONNECT         = 123;
export const REFINANCE_TURBO_VOIE_REPORTS               = 124;
export const REFINANCE_TURBO_EMPLOYMENT_TYPE            = 125;
export const REFINANCE_TURBO_EMPLOYEE_INFO              = 126;
export const REFINANCE_TURBO_RETIRED_INFO               = 127;
export const REFINANCE_TURBO_COBORROWER_EMPLOYMENT_TYPE = 128;
export const REFINANCE_TURBO_COBORROWER_EMPLOYEE_INFO   = 129;
export const REFINANCE_TURBO_BORROWER_DECLARATION       = 130;
export const REFINANCE_TURBO_COBORROWER_DECLARATION     = 131;
export const REFINANCE_TURBO_COLLECT_SSN_FINAL          = 132;
export const REFINANCE_TURBO_UPLOAD_DOCUMENTS           = 133;
export const REFINANCE_TURBO_MIN_LOAN_STOP_GATE         = 134;
export const REFINANCE_TURBO_NO_OPTIMAL_PROGRAMS        = 135;

export const REFINANCE_TURBO_COLLECT_SSN_QUESTION       = 1999;
export const REFINANCE_TURBO_COLLECT_SSN_QUESTION_FINAL = 2000;

