import React from 'react';
import { Field } from 'redux-form';
import { renderDateBase } from 'app/components/FormFields/_renderDateBase';
import { mapAutoCompleteName } from 'app/util/autocomplete';
import { required, noopValidate, isValidDate } from 'app/util/validators';

export const SingleDate = ({ ...props }) => {
  const { name, isOptional, label, inputClasses, labelClasses } = props;
  /* If the question isOptional, replace the required validator */
  const isRequired =  isOptional ? noopValidate : required;
  return (
    <Field
      autoComplete = {mapAutoCompleteName(name)}
      component    = {renderDateBase}
      inputClasses = {inputClasses}
      label        = {label}
      labelClasses = {labelClasses}
      name         = {name}
      validate     = {[isRequired, isValidDate]}
    />
  );
};
