import { SHARED, INCOME_DISCLAIMER } from 'app/models/questions/shared';
import { Currency } from 'app/components/FormFields/Currency';
import { IntegerAllowZero } from 'app/components/FormFields/IntegerAllowZero';
import { Name } from 'app/components/FormFields/Name';
import { RadioField } from 'app/components/FormFields/RadioField';
import { Select } from 'app/components/FormFields/Select';
import { SingleDate } from 'app/components/FormFields/SingleDate';
import { SinglePastDate } from 'app/components/FormFields/SinglePastDate';
import { Address } from 'app/components/FormFields/Address';
import { ZipCode } from 'app/components/FormFields/ZipCode';
import { TextInput } from 'app/components/FormFields/TextInput';
import { State } from 'app/components/FormFields/State';
import { CurrencyAllowZero } from 'app/components/FormFields/CurrencyAllowZero';
import {
  EMPLOYMENT_TYPE_OPTIONS,
  LIVING_SITUATION_OPTIONS,
  YES_OR_NO_OPTIONS,
  COBORROWER_EMPLOYMENT_TYPE_OPTIONS,
  ACCOUNT_TYPE_PURCHASE_OPTIONS,
  CONSTRUCTION_LOAN_TYPE,
} from 'app/models/options/options';
import { AdditionalProperties } from 'app/components/FormFieldArrays/AdditionalProperties';
import { BankAccounts } from 'app/components/FormFieldArrays/BankAccounts';
import { IncomeSources } from 'app/components/FormFieldArrays/IncomeSources';
import { RetirementIncomeSources } from 'app/components/FormFieldArrays/RetirementIncomeSources';
import { OtherIncomeSources } from 'app/components/FormFieldArrays/OtherIncomeSources';
import { Question } from 'app/models/types';
import { FieldNames } from 'app/models/fields/names';
import {
  hasHomeSold,
  hasCoBorrower,
  isHomeowner,
  isRenting,
  hasAddlProperties,
  isEmployee,
  isSelfEmployed,
  isLessThanTwoYrsAtJob,
  isRetired,
  isEmploymentOther,
  coBorrowerHasDifferentAddress,
  isCoBorrowerRenting,
  isLessThanTwoYrsAtLivingAddress,
  isCoLessThanTwoYrsAtJob,
  isCoBorrowerRetired,
  isCoBorrowerEmploymentOther,
  hasLandOwnership,
  hasShowAgesOfDependents,
  hasShowNumberOfDependents,
} from 'app/models/fields/conditionals';
import { CollectConditionallyRequiredSsn } from 'app/components/FormFields/Ssn/CollectConditionallyRequiredSsn';
import { AgesFFAComponent } from 'app/components/FormFieldArrays/Dynamic/Ages';
/**
 * Takes in a field name and returns the corresponding construction
 * question
 *
 * @param {string} name Field name
 * @returns {Question} Question with matching field name
 */
export const constructionLFQuestionByName = (name: string): Question => {
  return APPLICATION_CONSTRUCTION_QUESTIONS.find((question) => {
    return question.name === name;
  }) as Question;
};

/**
 * Takes in an array of field names and returns an array of questions
 *
 * @param {string[]} [names=[]] Field names
 * @returns {Question[]} Questions matching field names
 */
export const constructionLFQuestionsByName = (names: string[] = []): Question[] => {
  const questions: Question[] = [];
  for (const name of names) {
    questions.push(constructionLFQuestionByName(name));
  }
  return questions;
};

export const APPLICATION_CONSTRUCTION_QUESTIONS: Question[] = [
  {
    id: 1001,
    ...SHARED[FieldNames.firstName],
  },
  {
    id: 1002,
    ...SHARED[FieldNames.middleName],
  },
  {
    id: 1003,
    ...SHARED[FieldNames.lastName],
  },
  {
    id: 1004,
    ...SHARED[FieldNames.email],
  },
  {
    id: 1005,
    ...SHARED[FieldNames.phone],
  },
  {
    id: 1006,
    title: 'essential and not real title',
    ...SHARED[FieldNames.propertyUsage],
    component: Select,
  },
  {
    id       : 1013,
    component: ZipCode,
    label    : 'Zip Code',
    name     : FieldNames.propertyZip,
  },
  {
    id       : 1014,
    component: Address,
    label    : 'Street Address',
    name     : FieldNames.propertyStreet,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.propertyStreet,
    },
  },
  {
    id       : 1015,
    component: Address,
    label    : 'City',
    name     : FieldNames.propertyCity,
  },
  {
    id       : 1016,
    component: State,
    label    : 'State',
    name     : FieldNames.propertyState,
  },
  {
    id       : 1022,
    component: BankAccounts,
    options  : ACCOUNT_TYPE_PURCHASE_OPTIONS,
    name     : FieldNames.assets,
  },
  {
    id        : 1023,
    component : RadioField,
    name      : FieldNames.homeSoldYN,
    options   : YES_OR_NO_OPTIONS,
    title     : 'Will you be selling your current home?',
  },
  {
    id       : 1024,
    component: Currency,
    label    : 'Listing price of current home',
    name     : FieldNames.listingPrice,
    showIf   : [hasHomeSold],
  },
  {
    id       : 1025,
    component: CurrencyAllowZero,
    label    : 'Mortgage balance of current home',
    name     : FieldNames.mortgageBalance,
    showIf   : [hasHomeSold],
  },
  {
    id: 1027,
    ...SHARED[FieldNames.creditScore],
    title: 'What is your estimated credit score?',
  },
  {
    id: 1028,
    ...SHARED[FieldNames.maritalStatus],
  },
  {
    id       : 1029,
    component: IntegerAllowZero,
    name     : FieldNames.numOfDependent,
    title    : 'Number of dependents',
    showIf   : [hasShowNumberOfDependents],
  },
  {
    id       : 1017,
    component: AgesFFAComponent,
    name     : FieldNames.dependentsAges,
    title    : 'Do you have any Dependents?',
    showIf   : [hasShowAgesOfDependents],
  },
  {
    id: 1030,
    ...SHARED[FieldNames.dateOfBirth],
  },
  {
    id: 1031,
    component: RadioField,
    name     : FieldNames.coBorrowerYN,
    options  : YES_OR_NO_OPTIONS,
    title    : 'Will you have a co-borrower?',
  },
  {
    id: 1032,
    ...SHARED[FieldNames.firstName],
    name  : FieldNames.coBorrowerFirstName,
    title : 'Tell us about the co-borrower',
    showIf: [hasCoBorrower],
  },
  {
    id: 1033,
    ...SHARED[FieldNames.lastName],
    name  : FieldNames.coBorrowerLastName,
    showIf: [hasCoBorrower],
  },
  {
    id: 1034,
    ...SHARED[FieldNames.dateOfBirth],
    name  : FieldNames.coBorrowerDOB,
    showIf: [hasCoBorrower],
  },
  {
    id       : 1035,
    component: RadioField,
    name     : FieldNames.coBorrowerOnTitle,
    options  : YES_OR_NO_OPTIONS,
    title    : 'Will they be on the title?',
    showIf   : [hasCoBorrower],
  },
  {
    id       : 1036,
    component: RadioField,
    name     : FieldNames.livingRentOrOwn,
    options  : LIVING_SITUATION_OPTIONS,
  },
  {
    id: 1037,
    ...SHARED[FieldNames.propertyType],
    component   : Select,
    fieldPopover: true,
    name        : FieldNames.livingPropertyType,
    showIf      : [isHomeowner],
  },
  {
    id: 1038,
    ...SHARED[FieldNames.propertyZip],
    name  : FieldNames.livingPropertyZip,
  },
  {
    id       : 1039,
    component: SinglePastDate,
    label    : 'Occupancy Start Date',
    name     : FieldNames.livingOccupancyStart,
  },
  {
    id       : 1041,
    component: Currency,
    label    : 'Annual Taxes',
    name     : FieldNames.livingYearTaxes,
    showIf   : [isHomeowner],
  },
  {
    id       : 1042,
    component: Currency,
    label    : 'Annual Insurance',
    name     : FieldNames.livingYearInsure,
    showIf   : [isHomeowner],
  },
  {
    id        : 1043,
    component : Currency,
    isOptional: true,
    label     : 'Annual Homeowners Association Fees',
    name      : FieldNames.livingYearAssociate,
    showIf    : [isHomeowner],
  },
  {
    id: 1044,
    ...SHARED[FieldNames.escrowUsage],
    name  : FieldNames.livingEscrowUsage,
    showIf: [isHomeowner],
    title : 'Do you have an escrow account?',
  },
  {
    id       : 1045,
    component: Currency,
    label    : 'Monthly Rent Payment',
    name     : FieldNames.livingMonthlyRentAmount,
    showIf   : [isRenting],
  },
  {
    ...SHARED[FieldNames.hasAddlPropertiesYN],
    id     : 1046,
    showIf : [isHomeowner],
  },
  {
    id       : 1047,
    component: AdditionalProperties,
    name     : FieldNames.addlProperties,
    showIf   : [hasAddlProperties],
  },
  {
    id         : 1048,
    component  : RadioField,
    name       : FieldNames.employmentType,
    options    : EMPLOYMENT_TYPE_OPTIONS,
    disclaimer : INCOME_DISCLAIMER,
  },
  {
    id       : 1049,
    component: TextInput,
    label    : 'Company Name',
    name     : FieldNames.employCompanyName,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1051,
    component: Address,
    label    : 'Street Address',
    name     : FieldNames.employStreet,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1052,
    component: SinglePastDate,
    label    : 'Start Date',
    name     : FieldNames.employStart,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1053,
    component: Currency,
    label    : 'Monthly Base Pay',
    name     : FieldNames.employBase,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1054,
    component: TextInput,
    label    : 'Previous Employers Name',
    name     : FieldNames.employPrevName,
    title    : 'Previous employment information',
    showIf   : [isLessThanTwoYrsAtJob],
    popover  : 'Lenders will require previous employer information if you\'ve been with this employer for less than 2 years.',
  },
  {
    id: 1055,
    component: Address,
    label    : 'Street Address',
    name     : FieldNames.employPrevStreet,
    showIf   : [isLessThanTwoYrsAtJob],
  },
  {
    id       : 1056,
    component: SinglePastDate,
    label    : 'Start Date',
    name     : FieldNames.employPrevStart,
    showIf   : [isLessThanTwoYrsAtJob],
  },
  {
    id       : 1057,
    component: SingleDate,
    label    : 'End Date',
    name     : FieldNames.employPrevEnd,
    showIf   : [isLessThanTwoYrsAtJob],
  },
  {
    id          : 1058,
    component   : Currency,
    fieldPopover: true,
    isOptional  : true,
    label       : 'Monthly Overtime',
    name        : FieldNames.employOvertime,
    title       : 'Additional income sources?',
    showIf      : [isEmployee, isSelfEmployed],
    popover     : 'Overtime can be included in your income if it has been consistent over a two-year period.',
  },
  {
    id          : 1059,
    component   : Currency,
    fieldPopover: true,
    isOptional  : true,
    label       : 'Monthly Commission',
    name        : FieldNames.employCommission,
    showIf      : [isEmployee, isSelfEmployed],
    popover     : 'Commissions can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id          : 1060,
    component   : Currency,
    fieldPopover: true,
    isOptional  : true,
    label       : 'Monthly Bonus',
    name        : FieldNames.employBonus,
    showIf      : [isEmployee, isSelfEmployed],
    popover     : 'Bonuses can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id       : 1061,
    component: IncomeSources,
    name     : FieldNames.additionalIncome,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1062,
    component: RetirementIncomeSources,
    title    : 'Tell us about your retirement income',
    name     : FieldNames.retirementIncome,
    showIf   : [isRetired],
  },
  {
    id       : 1063,
    component: Address,
    label    : 'Street Address',
    name     : FieldNames.livingPropertyStreet,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPropertyStreet,
    },
  },
  {
    id       : 1064,
    component: Address,
    label    : 'City',
    name     : FieldNames.livingPropertyCity,
  },
  {
    id       : 1065,
    component: State,
    label    : 'State',
    name     : FieldNames.livingPropertyState,
  },
  {
    id        : 1068,
    component : Name,
    label     : 'Middle Name',
    name      : FieldNames.coBorrowerMiddleName,
    isOptional: true,
    showIf    : [hasCoBorrower],
  },
  {
    id        : 1069,
    component : Address,
    isOptional: true,
    label     : 'Street Address 2',
    name      : FieldNames.livingPropertyStreet2,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPropertyStreet2,
    },
  },
  {
    id        : 1070,
    component : Address,
    isOptional: true,
    label     : 'Street Address 2',
    name      : FieldNames.employStreet2,
    showIf    : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1071,
    component: Address,
    label    : 'City',
    name     : FieldNames.employCity,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1072,
    component: State,
    label    : 'State',
    name     : FieldNames.employState,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1073,
    component: ZipCode,
    label    : 'Zip Code',
    name     : FieldNames.employZip,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id        : 1074,
    component : Address,
    isOptional: true,
    label     : 'Street Address 2',
    name      : FieldNames.employPrevStreet2,
    showIf    : [isLessThanTwoYrsAtJob],
  },
  {
    id       : 1075,
    component: Address,
    label    : 'City',
    name     : FieldNames.employPrevCity,
    showIf   : [isLessThanTwoYrsAtJob],
  },
  {
    id       : 1076,
    component: State,
    label    : 'State',
    name     : FieldNames.employPrevState,
    showIf   : [isLessThanTwoYrsAtJob],
  },
  {
    id       : 1077,
    component: ZipCode,
    label    : 'Zip',
    name     : FieldNames.employPrevZip,
    showIf   : [isLessThanTwoYrsAtJob],
  },
  {
    id        : 1078,
    component : Address,
    isOptional: true,
    label     : 'Street Address 2',
    name      : FieldNames.propertyStreet2,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.propertyStreet2,
    },
  },
  {
    id       : 1079,
    component: OtherIncomeSources,
    title    : 'Tell us about your other income',
    name     : FieldNames.otherIncome,
    showIf   : [isEmploymentOther],
  },
  {
    id        : 1080,
    component : TextInput,
    label     : 'Job Title',
    isOptional: true,
    name      : FieldNames.employTitle,
    showIf    : [isEmployee, isSelfEmployed],
  },
  {
    ...SHARED[FieldNames.email],
    id     : 1082,
    name   : FieldNames.coBorrowerEmail,
    showIf : [hasCoBorrower],
  },
  {
    ...SHARED[FieldNames.phone],
    id     : 1083,
    name   : FieldNames.coBorrowerPhone,
    showIf : [hasCoBorrower],
  },
  {
    id        : 1084,
    component : RadioField,
    name      : FieldNames.coBorrowerSameAddress,
    options   : YES_OR_NO_OPTIONS,
    title     : 'Same address as borrower?',
  },
  {
    id        : 1085,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.coBorrowerLivingPropertyStreet,
    title     : `Co-borrower's current address?`,
    showIf    : [coBorrowerHasDifferentAddress],
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.coBorrowerLivingPropertyStreet,
    },
  },
  {
    id         : 1086,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.coBorrowerLivingPropertyStreet2,
    showIf     : [coBorrowerHasDifferentAddress],
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.coBorrowerLivingPropertyStreet2,
    },
  },
  {
    id        : 1087,
    component : Address,
    label     : 'City',
    name      : FieldNames.coBorrowerLivingPropertyCity,
    showIf    : [coBorrowerHasDifferentAddress],
  },
  {
    id        : 1088,
    component : State,
    label     : 'State',
    name      : FieldNames.coBorrowerLivingPropertyState,
    showIf    : [coBorrowerHasDifferentAddress],
  },
  {
    id        : 1089,
    component : ZipCode,
    label     : 'Zip Code',
    name      : FieldNames.coBorrowerLivingPropertyZip,
    showIf    : [coBorrowerHasDifferentAddress],
  },
  {
    ...SHARED[FieldNames.maritalStatus],
    id     : 1090,
    name   : FieldNames.coBorrowerMaritalStatus,
    title  : `Co-borrower's marital status?`,
    showIf : [hasCoBorrower],
  },
  {
    id        : 1091,
    component : RadioField,
    name      : FieldNames.coBorrowerLivingRentOrOwn,
    options   : LIVING_SITUATION_OPTIONS,
    title     : `Co-borrower's current housing situation?`,
    showIf    : [coBorrowerHasDifferentAddress],
  },
  {
    id        : 1092,
    component : Currency,
    label     : 'Monthly Rent Payment',
    name      : FieldNames.coBorrowerLivingMonthlyRentAmount,
    showIf    : [isCoBorrowerRenting],
  },
  {
    id        : 1093,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.livingPrevPropertyStreet,
    showIf    : [isLessThanTwoYrsAtLivingAddress],
    title     : 'Previous Address',
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPrevPropertyStreet,
    },
  },
  {
    id         : 1094,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.livingPrevPropertyStreet2,
    showIf     : [isLessThanTwoYrsAtLivingAddress],
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPrevPropertyStreet2,
    },
  },
  {
    id        : 1095,
    component : Address,
    label     : 'City',
    name      : FieldNames.livingPrevPropertyCity,
    showIf    : [isLessThanTwoYrsAtLivingAddress],
  },
  {
    id        : 1096,
    component : State,
    label     : 'State',
    name      : FieldNames.livingPrevPropertyState,
    showIf    : [isLessThanTwoYrsAtLivingAddress],
  },
  {
    ...SHARED[FieldNames.propertyZip],
    id     : 1097,
    name   : FieldNames.livingPrevPropertyZip,
    showIf : [isLessThanTwoYrsAtLivingAddress],
  },
  {
    id        : 1098,
    component : Select,
    name      : FieldNames.livingPrevRentOrOwn,
    options   : LIVING_SITUATION_OPTIONS,
    showIf    : [isLessThanTwoYrsAtLivingAddress],
    label     : 'Housing Situation',
  },
  {
    ...SHARED[FieldNames.phone],
    id   : 1099,
    name : FieldNames.employPhone,
  },
  {
    id        : 1100,
    component : RadioField,
    name      : FieldNames.coBorrowerEmploymentType,
    options   : COBORROWER_EMPLOYMENT_TYPE_OPTIONS,
  },
  {
    id        : 1101,
    component : TextInput,
    label     : 'Company Name',
    name      : FieldNames.coBorrowerEmployCompanyName,
  },
  {
    id        : 1102,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.coBorrowerEmployStreet,
  },
  {
    id         : 1103,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.coBorrowerEmployStreet2,
  },
  {
    id        : 1104,
    component : Address,
    label     : 'City',
    name      : FieldNames.coBorrowerEmployCity,
  },
  {
    id        : 1105,
    component : State,
    label     : 'State',
    name      : FieldNames.coBorrowerEmployState,
  },
  {
    id        : 1106,
    component : ZipCode,
    label     : 'Zip Code',
    name      : FieldNames.coBorrowerEmployZip,
  },
  {
    id         : 1107,
    component  : TextInput,
    label      : 'Job Title',
    isOptional : true,
    name       : FieldNames.coBorrowerEmployTitle,
  },
  {
    ...SHARED[FieldNames.phone],
    id   : 1108,
    name : FieldNames.coBorrowerEmployPhone,
  },
  {
    id        : 1109,
    component : SinglePastDate,
    label     : 'Start Date',
    name      : FieldNames.coBorrowerEmployStart,
  },
  {
    id        : 1110,
    component : Currency,
    label     : 'Monthly Base Pay',
    name      : FieldNames.coBorrowerEmployBase,
  },
  {
    id           : 1111,
    component    : Currency,
    fieldPopover : true,
    isOptional   : true,
    label        : 'Monthly Overtime',
    name         : FieldNames.coBorrowerEmployOvertime,
    title        : 'Additional income sources?',
    popover      : 'Overtime can be included in your income if it has been consistent over a two-year period.',
  },
  {
    id           : 1112,
    component    : Currency,
    fieldPopover : true,
    isOptional   : true,
    label        : 'Monthly Commission',
    name         : FieldNames.coBorrowerEmployCommission,
    popover      : 'Commissions can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id           : 1113,
    component    : Currency,
    fieldPopover : true,
    isOptional   : true,
    label        : 'Monthly Bonus',
    name         : FieldNames.coBorrowerEmployBonus,
    popover      : 'Bonuses can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id        : 1114,
    component : IncomeSources,
    name      : FieldNames.coBorrowerAdditionalIncome,
  },
  {
    id        : 1115,
    component : TextInput,
    label     : 'Previous Employers Name',
    name      : FieldNames.coBorrowerEmployPrevName,
    title     : 'Previous employment information',
    showIf    : [isCoLessThanTwoYrsAtJob],
    popover   : `Lenders will require previous employer information if you've been with this employer for less than 2 years.`,
  },
  {
    id        : 1116,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.coBorrowerEmployPrevStreet,
    showIf    : [isCoLessThanTwoYrsAtJob],
  },
  {
    id         : 1117,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.coBorrowerEmployPrevStreet2,
    showIf     : [isCoLessThanTwoYrsAtJob],
  },
  {
    id        : 1118,
    component : Address,
    label     : 'City',
    name      : FieldNames.coBorrowerEmployPrevCity,
    showIf    : [isCoLessThanTwoYrsAtJob],
  },
  {
    id        : 1119,
    component : State,
    label     : 'State',
    name      : FieldNames.coBorrowerEmployPrevState,
    showIf    : [isCoLessThanTwoYrsAtJob],
  },
  {
    id        : 1120,
    component : ZipCode,
    label     : 'Zip',
    name      : FieldNames.coBorrowerEmployPrevZip,
    showIf    : [isCoLessThanTwoYrsAtJob],
  },
  {
    id        : 1121,
    component : SinglePastDate,
    label     : 'Start Date',
    name      : FieldNames.coBorrowerEmployPrevStart,
    showIf    : [isCoLessThanTwoYrsAtJob],
  },
  {
    id        : 1122,
    component : SingleDate,
    label     : 'End Date',
    name      : FieldNames.coBorrowerEmployPrevEnd,
    showIf    : [isCoLessThanTwoYrsAtJob],
  },
  {
    id        : 1123,
    component : RetirementIncomeSources,
    title     : `Tell us about your co-borrower's retirement income`,
    name      : FieldNames.coBorrowerRetirementIncome,
    showIf    : [isCoBorrowerRetired],
  },
  {
    id        : 1124,
    component : OtherIncomeSources,
    title     : `Tell us about your co-borrower's other income`,
    name      : FieldNames.coBorrowerOtherIncome,
    showIf    : [isCoBorrowerEmploymentOther],
  },
  {
    id: 1127,
    ...SHARED[FieldNames.suffixName],
  },
  {
    id: 1128,
    ...SHARED[FieldNames.coBorrowerSuffixName],
  },
  {
    id: 1129,
    ...SHARED[FieldNames.employPrevTitle],
  },
  {
    id: 1130,
    ...SHARED[FieldNames.coBorrowerEmployPrevTitle],
  },
  {
    id: 1131,
    ...SHARED[FieldNames.citizenshipType],
  },
  {
    id: 1132,
    ...SHARED[FieldNames.coBorrowerCitizenshipType],
  },
  {
    id: 1133,
    ...SHARED[FieldNames.militaryExperience],
  },
  {
    id: 1134,
    ...SHARED[FieldNames.coBorrowerMilitaryExperience],
  },
  {
    id: 1135,
    ...SHARED[FieldNames.employOwnershipShare],
  },
  {
    id: 1136,
    ...SHARED[FieldNames.coBorrowerEmployOwnershipShare],
  },
  {
    id: 1137,
    ...SHARED[FieldNames.employPartyTransaction],
  },
  {
    id: 1138,
    ...SHARED[FieldNames.coBorrowerEmployPartyTransaction],
  },
  {
    id       : 1140,
    component: RadioField,
    name     : FieldNames.constructionLoanType,
    options  : CONSTRUCTION_LOAN_TYPE,
    title    : 'What type of construction loan would you like?',
  },
  {
    id       : 1141,
    component: Currency,
    title    : 'What are the expected construction costs?',
    name     : FieldNames.constructionCost,
  },
  {
    id       : 1141,
    component: Currency,
    title    : 'What is your expected down payment?',
    isOptional   : true,
    name     : FieldNames.downDollar,
  },
  {
    id       : 1142,
    component: RadioField,
    name     : FieldNames.constructionLandOwner,
    options  : YES_OR_NO_OPTIONS,
    title    : 'Do you own the lot where the construction will occur?',
  },
  {
    id       : 1143,
    component: SinglePastDate,
    title    : 'When was the lot acquired?',
    name     : FieldNames.constructionLandAcquired,
    showIf   : [hasLandOwnership],
  },
  {
    id       : 1141,
    component: Currency,
    title    : 'What was the original cost of the lot?',
    name     : FieldNames.constructionLandCost,
    showIf   : [hasLandOwnership],
  },
  {
    id        : 1142,
    component : CollectConditionallyRequiredSsn,
    name      : FieldNames.ssnQuestionRequirement,
  },
];
