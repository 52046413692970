import { SHARED, INCOME_DISCLAIMER } from 'app/models/questions/shared';
import { Currency } from 'app/components/FormFields/Currency';
import { IntegerAllowZero } from 'app/components/FormFields/IntegerAllowZero';
import { Name } from 'app/components/FormFields/Name';
import { PurchaseDownPayment } from 'app/components/FormFields/PurchaseDownPayment';
import { RadioField } from 'app/components/FormFields/RadioField';
import { Select } from 'app/components/FormFields/Select';
import { SingleDate } from 'app/components/FormFields/SingleDate';
import { SingleFutureDate } from 'app/components/FormFields/SingleFutureDate';
import { SinglePastDate } from 'app/components/FormFields/SinglePastDate';
import { Address } from 'app/components/FormFields/Address';
import { ZipCode } from 'app/components/FormFields/ZipCode';
import { TextInput } from 'app/components/FormFields/TextInput';
import { State } from 'app/components/FormFields/State';
import { CurrencyAllowZero } from 'app/components/FormFields/CurrencyAllowZero';
import { EMPLOYMENT_TYPE_OPTIONS, LIVING_SITUATION_OPTIONS, YES_OR_NO_OPTIONS, COBORROWER_EMPLOYMENT_TYPE_OPTIONS, ACCOUNT_TYPE_PURCHASE_OPTIONS } from 'app/models/options/options';
import { AdditionalProperties } from 'app/components/FormFieldArrays/AdditionalProperties';
import { BankAccounts } from 'app/components/FormFieldArrays/BankAccounts';
import { IncomeSources } from 'app/components/FormFieldArrays/IncomeSources';
import { RetirementIncomeSources } from 'app/components/FormFieldArrays/RetirementIncomeSources';
import { OtherIncomeSources } from 'app/components/FormFieldArrays/OtherIncomeSources';
import { AssetSummary } from 'app/components/AssetSummary';
import { Question } from 'app/models/types';
import { FieldNames } from 'app/models/fields/names';
import {
  hasChosenHome,
  hasAgreement,
  noAgreement,
  hasPrequalAmount,
  hasHomeSold,
  hasCoBorrower,
  isHomeowner,
  isRenting,
  hasAddlProperties,
  isEmployee,
  isSelfEmployed,
  isLessThanTwoYrsAtJob,
  isRetired,
  isEmploymentOther,
  coBorrowerHasDifferentAddress,
  isCoBorrowerRenting,
  isLessThanTwoYrsAtLivingAddress,
  isCoLessThanTwoYrsAtJob,
  isCoBorrowerRetired,
  isCoBorrowerEmploymentOther,
  hasShowAgesOfDependents,
  hasShowNumberOfDependents, hasAddress, isNo,
} from 'app/models/fields/conditionals';
import { CollectConditionallyRequiredSsn } from 'app/components/FormFields/Ssn/CollectConditionallyRequiredSsn';
import { AgesFFAComponent } from 'app/components/FormFieldArrays/Dynamic/Ages';

export const APPLICATION_LAND_QUESTIONS: Question[] = [
  {
    id: 1001,
    ...SHARED[FieldNames.firstName],
  },
  {
    id: 1002,
    ...SHARED[FieldNames.middleName],
  },
  {
    id: 1003,
    ...SHARED[FieldNames.lastName],
  },
  {
    id: 1004,
    ...SHARED[FieldNames.email],
  },
  {
    id: 1005,
    ...SHARED[FieldNames.phone],
  },
  {
    id       : 1006,
    component: RadioField,
    name     : FieldNames.agreementYN,
    options  : YES_OR_NO_OPTIONS,
    title    : 'Do you have a signed purchase agreement for your vacant land?',
  },
  {
    id         : 1008,
    component  : PurchaseDownPayment,
    hideTitle  : true,
    label      : 'Value of Land',
    name       : FieldNames.homeValue,
    showIf     : [
      hasAgreement,
      noAgreement,
      hasPrequalAmount,
    ],
  },
  {
    id        : 1009,
    component : SingleFutureDate,
    label     : 'Expected Closing Date',
    name      : FieldNames.expectedClosingDate,
    showIf    : [hasChosenHome && hasAgreement],
    isOptional: true,
  },
  {
    id        : 1010,
    component : RadioField,
    name      : FieldNames.verifyAddress,
    options   : YES_OR_NO_OPTIONS,
    title     : 'Does the vacant land have an address?',

  },
  {
    id       : 1011,
    component: ZipCode,
    label    : 'Zip Code',
    name     : FieldNames.propertyZip,
    showIf   : [hasAddress],
  },
  {
    id       : 1012,
    component: Address,
    label    : 'Street Address',
    name     : FieldNames.propertyStreet,
    showIf   : [hasAddress],
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.propertyStreet,
    },
  },
  {
    id       : 1013,
    component: Address,
    label    : 'City',
    name     : FieldNames.propertyCity,
    showIf   : [hasAddress],
  },
  {
    id       : 1016,
    component: State,
    label    : 'State',
    name     : FieldNames.propertyState,
    showIf   : [hasAddress],
  },
  {
    id       : 1017,
    component: TextInput,
    name     : FieldNames.parcelId,
    title    : 'Plot or Parcel ID',
    showIf   : [(values) => isNo(values[FieldNames.verifyAddress])],
  },
  {
    id       : 1018,
    component: Currency,
    label    : 'Annual Taxes',
    name     : FieldNames.yearTaxes,
    title    : 'What are your expected taxes?',
  },
  {
    id       : 1019,
    component: BankAccounts,
    options  : ACCOUNT_TYPE_PURCHASE_OPTIONS,
    name     : FieldNames.assets,
  },
  {
    id        : 1020,
    component : RadioField,
    name      : FieldNames.homeSoldYN,
    options   : YES_OR_NO_OPTIONS,
    title     : 'Will you be selling your current home?',
  },
  {
    id       : 1021,
    component: Currency,
    label    : 'Listing price of current home',
    name     : FieldNames.listingPrice,
    showIf   : [hasHomeSold],
  },
  {
    id       : 1022,
    component: CurrencyAllowZero,
    label    : 'Mortgage balance of current home',
    name     : FieldNames.mortgageBalance,
    showIf   : [hasHomeSold],
  },
  {
    id: 1023,
    ...SHARED[FieldNames.creditScore],
    title: 'What is your estimated credit score?',
  },
  {
    id: 1024,
    ...SHARED[FieldNames.maritalStatus],
  },
  {
    id       : 1025,
    component: IntegerAllowZero,
    name     : FieldNames.numOfDependent,
    title    : 'Number of dependents',
    showIf   : [hasShowNumberOfDependents],
  },
  {
    id       : 1026,
    component: AgesFFAComponent,
    name     : FieldNames.dependentsAges,
    title    : 'Do you have any Dependents?',
    showIf   : [hasShowAgesOfDependents],
  },
  {
    id: 1027,
    ...SHARED[FieldNames.dateOfBirth],
  },
  {
    id: 1028,
    component: RadioField,
    name     : FieldNames.coBorrowerYN,
    options  : YES_OR_NO_OPTIONS,
    title    : 'Will you have a co-borrower?',
  },
  {
    id: 1029,
    ...SHARED[FieldNames.firstName],
    name  : FieldNames.coBorrowerFirstName,
    title : 'Tell us about the co-borrower',
    showIf: [hasCoBorrower],
  },
  {
    id: 1030,
    ...SHARED[FieldNames.lastName],
    name  : FieldNames.coBorrowerLastName,
    showIf: [hasCoBorrower],
  },
  {
    id: 1031,
    ...SHARED[FieldNames.dateOfBirth],
    name  : FieldNames.coBorrowerDOB,
    showIf: [hasCoBorrower],
  },
  {
    id       : 1032,
    component: RadioField,
    name     : FieldNames.coBorrowerOnTitle,
    options  : YES_OR_NO_OPTIONS,
    title    : 'Will they be on the title?',
    showIf   : [hasCoBorrower],
  },
  {
    id       : 1033,
    component: RadioField,
    name     : FieldNames.livingRentOrOwn,
    options  : LIVING_SITUATION_OPTIONS,
  },
  {
    id: 1035,
    ...SHARED[FieldNames.propertyZip],
    name  : FieldNames.livingPropertyZip,
  },
  {
    id       : 1036,
    component: SinglePastDate,
    label    : 'Occupancy Start Date',
    name     : FieldNames.livingOccupancyStart,
  },
  {
    id: 1037,
    ...SHARED[FieldNames.propertyType],
    component   : Select,
    fieldPopover: true,
    name        : FieldNames.livingPropertyType,
    showIf      : [isHomeowner],
  },
  {
    id       : 1038,
    component: Currency,
    label    : 'Annual Taxes',
    name     : FieldNames.livingYearTaxes,
    showIf   : [isHomeowner],
  },
  {
    id       : 1039,
    component: Currency,
    label    : 'Annual Insurance',
    name     : FieldNames.livingYearInsure,
    showIf   : [isHomeowner],
  },
  {
    id        : 1040,
    component : Currency,
    isOptional: true,
    label     : 'Annual Homeowners Association Fees',
    name      : FieldNames.livingYearAssociate,
    showIf    : [isHomeowner],
  },
  {
    id: 1041,
    ...SHARED[FieldNames.escrowUsage],
    name  : FieldNames.livingEscrowUsage,
    showIf: [isHomeowner],
    title : 'Do you have an escrow account?',
  },
  {
    id       : 1042,
    component: Currency,
    label    : 'Monthly Rent Payment',
    name     : FieldNames.livingMonthlyRentAmount,
    showIf   : [isRenting],
  },
  {
    ...SHARED[FieldNames.hasAddlPropertiesYN],
    id     : 1043,
    showIf : [isHomeowner],
  },
  {
    id       : 1044,
    component: AdditionalProperties,
    name     : FieldNames.addlProperties,
    showIf   : [hasAddlProperties],
  },
  {
    id         : 1045,
    component  : RadioField,
    name       : FieldNames.employmentType,
    options    : EMPLOYMENT_TYPE_OPTIONS,
    disclaimer : INCOME_DISCLAIMER,
  },
  {
    id       : 1046,
    component: TextInput,
    label    : 'Company Name',
    name     : FieldNames.employCompanyName,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1048,
    component: Address,
    label    : 'Street Address',
    name     : FieldNames.employStreet,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1049,
    component: SinglePastDate,
    label    : 'Start Date',
    name     : FieldNames.employStart,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1050,
    component: Currency,
    label    : 'Monthly Base Pay',
    name     : FieldNames.employBase,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1051,
    component: TextInput,
    label    : 'Previous Employers Name',
    name     : FieldNames.employPrevName,
    title    : 'Previous employment information',
    showIf   : [isLessThanTwoYrsAtJob],
    popover  : `Lenders will require previous employer information if you've been with this employer for less than 2 years.`,
  },
  {
    id       : 1053,
    component: Address,
    label    : 'Street Address',
    name     : FieldNames.employPrevStreet,
    showIf   : [isLessThanTwoYrsAtJob],
  },
  {
    id       : 1054,
    component: SinglePastDate,
    label    : 'Start Date',
    name     : FieldNames.employPrevStart,
    showIf   : [isLessThanTwoYrsAtJob],
  },
  {
    id       : 1055,
    component: SingleDate,
    label    : 'End Date',
    name     : FieldNames.employPrevEnd,
    showIf   : [isLessThanTwoYrsAtJob],
  },
  {
    id          : 1056,
    component   : Currency,
    fieldPopover: true,
    isOptional  : true,
    label       : 'Monthly Overtime',
    name        : FieldNames.employOvertime,
    title       : 'Additional income sources?',
    showIf      : [isEmployee, isSelfEmployed],
    popover     : 'Overtime can be included in your income if it has been consistent over a two-year period.',
  },
  {
    id          : 1057,
    component   : Currency,
    fieldPopover: true,
    isOptional  : true,
    label       : 'Monthly Commission',
    name        : FieldNames.employCommission,
    showIf      : [isEmployee, isSelfEmployed],
    popover     : 'Commissions can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id          : 1058,
    component   : Currency,
    fieldPopover: true,
    isOptional  : true,
    label       : 'Monthly Bonus',
    name        : FieldNames.employBonus,
    showIf      : [isEmployee, isSelfEmployed],
    popover     : 'Bonuses can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id       : 1059,
    component: IncomeSources,
    name     : FieldNames.additionalIncome,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1060,
    component: RetirementIncomeSources,
    title    : 'Tell us about your retirement income',
    name     : FieldNames.retirementIncome,
    showIf   : [isRetired],
  },
  {
    id       : 1061,
    component: Address,
    label    : 'Street Address',
    name     : FieldNames.livingPropertyStreet,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPropertyStreet,
    },
  },
  {
    id       : 1062,
    component: Address,
    label    : 'City',
    name     : FieldNames.livingPropertyCity,
  },
  {
    id       : 1063,
    component: State,
    label    : 'State',
    name     : FieldNames.livingPropertyState,
  },
  {
    id       : 1064,
    component: AssetSummary,
    name     : FieldNames.assetSummary,
  },
  {
    id        : 1065,
    component : Name,
    label     : 'Middle Name',
    name      : FieldNames.coBorrowerMiddleName,
    isOptional: true,
    showIf    : [hasCoBorrower],
  },
  {
    id        : 1066,
    component : Address,
    isOptional: true,
    label     : 'Street Address 2',
    name      : FieldNames.livingPropertyStreet2,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPropertyStreet2,
    },
  },
  {
    id        : 1067,
    component : Address,
    isOptional: true,
    label     : 'Street Address 2',
    name      : FieldNames.employStreet2,
    showIf    : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1068,
    component: Address,
    label    : 'City',
    name     : FieldNames.employCity,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1069,
    component: State,
    label    : 'State',
    name     : FieldNames.employState,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1070,
    component: ZipCode,
    label    : 'Zip Code',
    name     : FieldNames.employZip,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id        : 1071,
    component : Address,
    isOptional: true,
    label     : 'Street Address 2',
    name      : FieldNames.employPrevStreet2,
    showIf    : [isLessThanTwoYrsAtJob],
  },
  {
    id       : 1072,
    component: Address,
    label    : 'City',
    name     : FieldNames.employPrevCity,
    showIf   : [isLessThanTwoYrsAtJob],
  },
  {
    id       : 1073,
    component: State,
    label    : 'State',
    name     : FieldNames.employPrevState,
    showIf   : [isLessThanTwoYrsAtJob],
  },
  {
    id       : 1074,
    component: ZipCode,
    label    : 'Zip',
    name     : FieldNames.employPrevZip,
    showIf   : [isLessThanTwoYrsAtJob],
  },
  {
    id        : 1075,
    component : Address,
    isOptional: true,
    label     : 'Street Address 2',
    name      : FieldNames.propertyStreet2,
    showIf   : [hasAddress],
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.propertyStreet2,
    },
  },
  {
    id       : 1076,
    component: OtherIncomeSources,
    title    : 'Tell us about your other income',
    name     : FieldNames.otherIncome,
    showIf   : [isEmploymentOther],
  },
  {
    id        : 1077,
    component : TextInput,
    label     : 'Job Title',
    isOptional: true,
    name      : FieldNames.employTitle,
    showIf    : [isEmployee, isSelfEmployed],
  },
  {
    ...SHARED[FieldNames.email],
    id     : 1078,
    name   : FieldNames.coBorrowerEmail,
    showIf : [hasCoBorrower],
  },
  {
    ...SHARED[FieldNames.phone],
    id     : 1079,
    name   : FieldNames.coBorrowerPhone,
    showIf : [hasCoBorrower],
  },
  {
    id        : 1080,
    component : RadioField,
    name      : FieldNames.coBorrowerSameAddress,
    options   : YES_OR_NO_OPTIONS,
    title     : 'Same address as borrower?',
  },
  {
    id        : 1081,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.coBorrowerLivingPropertyStreet,
    title     : `Co-borrower's current address?`,
    showIf    : [coBorrowerHasDifferentAddress],
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.coBorrowerLivingPropertyStreet,
    },
  },
  {
    id         : 1082,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.coBorrowerLivingPropertyStreet2,
    showIf     : [coBorrowerHasDifferentAddress],
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.coBorrowerLivingPropertyStreet2,
    },
  },
  {
    id        : 1083,
    component : Address,
    label     : 'City',
    name      : FieldNames.coBorrowerLivingPropertyCity,
    showIf    : [coBorrowerHasDifferentAddress],
  },
  {
    id        : 1084,
    component : State,
    label     : 'State',
    name      : FieldNames.coBorrowerLivingPropertyState,
    showIf    : [coBorrowerHasDifferentAddress],
  },
  {
    id        : 1085,
    component : ZipCode,
    label     : 'Zip Code',
    name      : FieldNames.coBorrowerLivingPropertyZip,
    showIf    : [coBorrowerHasDifferentAddress],
  },
  {
    ...SHARED[FieldNames.maritalStatus],
    id     : 1086,
    name   : FieldNames.coBorrowerMaritalStatus,
    title  : `Co-borrower's marital status?`,
    showIf : [hasCoBorrower],
  },
  {
    id        : 1087,
    component : RadioField,
    name      : FieldNames.coBorrowerLivingRentOrOwn,
    options   : LIVING_SITUATION_OPTIONS,
    title     : `Co-borrower's current housing situation?`,
    showIf    : [coBorrowerHasDifferentAddress],
  },
  {
    id        : 1088,
    component : Currency,
    label     : 'Monthly Rent Payment',
    name      : FieldNames.coBorrowerLivingMonthlyRentAmount,
    showIf    : [isCoBorrowerRenting],
  },
  {
    id        : 1089,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.livingPrevPropertyStreet,
    showIf    : [isLessThanTwoYrsAtLivingAddress],
    title     : 'Previous Address',
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPrevPropertyStreet,
    },
  },
  {
    id         : 1090,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.livingPrevPropertyStreet2,
    showIf     : [isLessThanTwoYrsAtLivingAddress],
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPrevPropertyStreet2,
    },
  },
  {
    id        : 1091,
    component : Address,
    label     : 'City',
    name      : FieldNames.livingPrevPropertyCity,
    showIf    : [isLessThanTwoYrsAtLivingAddress],
  },
  {
    id        : 1092,
    component : State,
    label     : 'State',
    name      : FieldNames.livingPrevPropertyState,
    showIf    : [isLessThanTwoYrsAtLivingAddress],
  },
  {
    ...SHARED[FieldNames.propertyZip],
    id     : 1093,
    name   : FieldNames.livingPrevPropertyZip,
    showIf : [isLessThanTwoYrsAtLivingAddress],
  },
  {
    id        : 1094,
    component : Select,
    name      : FieldNames.livingPrevRentOrOwn,
    options   : LIVING_SITUATION_OPTIONS,
    showIf    : [isLessThanTwoYrsAtLivingAddress],
    label     : 'Housing Situation',
  },
  {
    id       : 1095,
    component: SinglePastDate,
    label    : 'Occupancy Start Date',
    name     : FieldNames.livingPrevOccupancyStart,
    showIf   : [isLessThanTwoYrsAtLivingAddress],
  },
  {
    ...SHARED[FieldNames.phone],
    id   : 1096,
    name : FieldNames.employPhone,
  },
  {
    id        : 1097,
    component : RadioField,
    name      : FieldNames.coBorrowerEmploymentType,
    options   : COBORROWER_EMPLOYMENT_TYPE_OPTIONS,
  },
  {
    id        : 1098,
    component : TextInput,
    label     : 'Company Name',
    name      : FieldNames.coBorrowerEmployCompanyName,
  },
  {
    id        : 1099,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.coBorrowerEmployStreet,
  },
  {
    id         : 1100,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.coBorrowerEmployStreet2,
  },
  {
    id        : 1101,
    component : Address,
    label     : 'City',
    name      : FieldNames.coBorrowerEmployCity,
  },
  {
    id        : 1102,
    component : State,
    label     : 'State',
    name      : FieldNames.coBorrowerEmployState,
  },
  {
    id        : 1103,
    component : ZipCode,
    label     : 'Zip Code',
    name      : FieldNames.coBorrowerEmployZip,
  },
  {
    id         : 1104,
    component  : TextInput,
    label      : 'Job Title',
    isOptional : true,
    name       : FieldNames.coBorrowerEmployTitle,
  },
  {
    ...SHARED[FieldNames.phone],
    id   : 1105,
    name : FieldNames.coBorrowerEmployPhone,
  },
  {
    id        : 1106,
    component : SinglePastDate,
    label     : 'Start Date',
    name      : FieldNames.coBorrowerEmployStart,
  },
  {
    id        : 1107,
    component : Currency,
    label     : 'Monthly Base Pay',
    name      : FieldNames.coBorrowerEmployBase,
  },
  {
    id           : 1108,
    component    : Currency,
    fieldPopover : true,
    isOptional   : true,
    label        : 'Monthly Overtime',
    name         : FieldNames.coBorrowerEmployOvertime,
    title        : 'Additional income sources?',
    popover      : 'Overtime can be included in your income if it has been consistent over a two-year period.',
  },
  {
    id           : 1109,
    component    : Currency,
    fieldPopover : true,
    isOptional   : true,
    label        : 'Monthly Commission',
    name         : FieldNames.coBorrowerEmployCommission,
    popover      : 'Commissions can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id           : 1110,
    component    : Currency,
    fieldPopover : true,
    isOptional   : true,
    label        : 'Monthly Bonus',
    name         : FieldNames.coBorrowerEmployBonus,
    popover      : 'Bonuses can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id        : 1111,
    component : IncomeSources,
    name      : FieldNames.coBorrowerAdditionalIncome,
  },
  {
    id        : 1112,
    component : TextInput,
    label     : 'Previous Employers Name',
    name      : FieldNames.coBorrowerEmployPrevName,
    title     : 'Previous employment information',
    showIf    : [isCoLessThanTwoYrsAtJob],
    popover   : `Lenders will require previous employer information if you've been with this employer for less than 2 years.`,
  },
  {
    id        : 1113,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.coBorrowerEmployPrevStreet,
    showIf    : [isCoLessThanTwoYrsAtJob],
  },
  {
    id         : 1114,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.coBorrowerEmployPrevStreet2,
    showIf     : [isCoLessThanTwoYrsAtJob],
  },
  {
    id        : 1115,
    component : Address,
    label     : 'City',
    name      : FieldNames.coBorrowerEmployPrevCity,
    showIf    : [isCoLessThanTwoYrsAtJob],
  },
  {
    id        : 1116,
    component : State,
    label     : 'State',
    name      : FieldNames.coBorrowerEmployPrevState,
    showIf    : [isCoLessThanTwoYrsAtJob],
  },
  {
    id        : 1117,
    component : ZipCode,
    label     : 'Zip',
    name      : FieldNames.coBorrowerEmployPrevZip,
    showIf    : [isCoLessThanTwoYrsAtJob],
  },
  {
    id        : 1118,
    component : SinglePastDate,
    label     : 'Start Date',
    name      : FieldNames.coBorrowerEmployPrevStart,
    showIf    : [isCoLessThanTwoYrsAtJob],
  },
  {
    id        : 1119,
    component : SingleDate,
    label     : 'End Date',
    name      : FieldNames.coBorrowerEmployPrevEnd,
    showIf    : [isCoLessThanTwoYrsAtJob],
  },
  {
    id        : 1120,
    component : RetirementIncomeSources,
    title     : `Tell us about your co-borrower's retirement income`,
    name      : FieldNames.coBorrowerRetirementIncome,
    showIf    : [isCoBorrowerRetired],
  },
  {
    id        : 1121,
    component : OtherIncomeSources,
    title     : `Tell us about your co-borrower's other income`,
    name      : FieldNames.coBorrowerOtherIncome,
    showIf    : [isCoBorrowerEmploymentOther],
  },
  {
    id: 1122,
    ...SHARED[FieldNames.suffixName],
  },
  {
    id: 1123,
    ...SHARED[FieldNames.coBorrowerSuffixName],
  },
  {
    id: 1124,
    ...SHARED[FieldNames.employPrevTitle],
  },
  {
    id: 1125,
    ...SHARED[FieldNames.coBorrowerEmployPrevTitle],
  },
  {
    id: 1126,
    ...SHARED[FieldNames.citizenshipType],
  },
  {
    id: 1127,
    ...SHARED[FieldNames.coBorrowerCitizenshipType],
  },
  {
    id: 1128,
    ...SHARED[FieldNames.militaryExperience],
  },
  {
    id: 1129,
    ...SHARED[FieldNames.coBorrowerMilitaryExperience],
  },
  {
    id: 1130,
    ...SHARED[FieldNames.employOwnershipShare],
  },
  {
    id: 1131,
    ...SHARED[FieldNames.coBorrowerEmployOwnershipShare],
  },
  {
    id: 1132,
    ...SHARED[FieldNames.employPartyTransaction],
  },
  {
    id: 1133,
    ...SHARED[FieldNames.coBorrowerEmployPartyTransaction],
  },
  {
    ...SHARED[FieldNames.propertyMonthlyRentalIncome],
    id     : 1134,
    label  : 'Expected Monthly Rental Income',
  },
  {
    id        : 1135,
    component : CollectConditionallyRequiredSsn,
    name      : FieldNames.ssnQuestionRequirement,
  },
];
