import { AppConfigState } from 'app/reducers/app-config';
import { RateInfo } from 'app/models/types';
import { FieldRequirementType, LivingSituation } from 'app/models/options/enums';

export const appConfigMock: AppConfigState = {
  aboutUsImage         : 'aboutUsImage',
  accountImage         : 'Account02.jpg',
  branchHomeEquityImage: 'BranchHomeEquity02.jpg',
  branchLandingImage   : 'BranchLanding02.jpg',
  branchMortgageImage  : 'BranchMortgage02.jpg',
  cashOutMaxLTV        : undefined,
  clientDisplayName    : 'Flagstar Bank',
  clientMinLoan        : 50000,
  confirmationImage    : 'Confirmation02.jpg',
  contactMethods       : [],
  contactImage         : 'Contact02.jpg',
  documentUploaders    : [],
  host                 : 'http://localhost',
  isLoading            : true,
  logo                 : 'Logo02.svg',
  logoWhite            : 'LogoWhite02.svg',
  name                 : 'Flagstar Mortgage',
  prefooterTemplate    : 'homeEquity',
  prequalSummaryImage  : 'PrequalSummary02.jpg',
  primaryColor         : '#254458',
  products             : [],
  ratesCustomerId      : '23lful02',
  secondaryColor       : '#3E769A',
  slug                 : 'flagstar',
  stopGateHelocImage   : 'StopGateHeloc02.jpg',
  stopGateImage        : 'StopGate02.jpg',
  stopGateReferralImage: 'StopGateReferral02.jpg',
  stopGateVeteranImage : 'StopGateVeteran02.jpg',
  tertiaryColor        : '#E9DCCC',
  featureToggles       : {
    isDefaultedHMDAAnswer              : true,
    isHideDependentsAges               : false,
    isHideGovtMonitoring               : true,
    isHideGovtMonitoringHelocArm       : true,
    isAutoPrequalEnabled               : false,
    isMultiLangEnabled                 : false,
    isTermsOfUseEnabled                : false,
    isBrokerEnabled                    : false,
    isCollectingMailingAddress         : false,
    isHideFeesOnRates                  : false,
    isHideConstructionLoanTypeQuestion : false,
    isHideEscrowQuestions              : false,
    isHideStreetAddressFields          : false,
    isHideVeteranQuestions             : false,
    isHideUniqueHomeEquityQuestion     : true,
    isHideUniquePersonalLoanQuestion   : true,
    isHideCreditCardLimitQuestion      : false,
    hasAutoLoanSpecialCalculation      : false,
    hasCreditBoosterPrograms           : false,
    hasDisableLogoRedirect             : false,
    hasLineOfWorkQuestion              : false,
    hasLoanTypeQuestion                : false,
    hasReverseAgeQuestion              : false,
    hasAssetFinancialInstitutionField  : false,
    hideDigitalMortgageOptions         : "",
    isHidePurchasePropertyAddress      : false,
    hideHomeEquityOptions              : "",
    hideReverseMortgageOptions         : "",
    ssnQuestionRequirement             : FieldRequirementType.HIDDEN,
    hasEnabledCreditReportOnTurbo      : true,
  },
  creditCards: [],
  ficoRanges: [],
  referralLimits: [],
};
export const contactUsMockReponse = {
  loEmail: '[{"email":"flagstar@lenderful.com","status":"sent","_id":"8c000b871ac44bae80e162632d74e622","reject_reason":null}]',
  customerEmail: '[{"email":"test@lenderful.com","status":"sent","_id":"a7648a79decb4dacbafa18b8039b6f27","reject_reason":null}]',
};
export const ratesMockResponse: RateInfo[] = [
  {
    loanType: '30 Year Fixed Conventional',
    term: 30,
    productName: 'Conf 30 Yr Fixed',
    productFamily: 'CONVENTIONAL',
    productType: 'FIXED',
    principalAndInterestPayment: 976.87,
    pmiMonthlyPayment: 195,
    rate: 4.625,
    apr: 5.103,
    quoteFees: -637.80,
    applyUrl: '/v2/application/?rate=4.625&type=F30+YEAR+FIXED+CONVENTIONAL&fees=-637.80',
    loanAmount: 90578.4362223378,
    downPayment: 5000,
  },
  {
    loanType: '15 Year Fixed Conventional',
    term: 15,
    productName: 'Conf 15 Yr Fixed',
    productFamily: 'CONVENTIONAL',
    productType: 'FIXED',
    principalAndInterestPayment: 1176.55,
    pmiMonthlyPayment: 195,
    rate: 4.250,
    apr: 4.610,
    quoteFees: -29.80,
    applyUrl: '/v2/application/?rate=4.250&type=F15+YEAR+FIXED+CONVENTIONAL&fees=-29.80',
    loanAmount: 554445,
    downPayment: 50500,
  },
];
export const longformMockResponse = {
  LOAN_APPLICATION: {
    BORROWER: [{
      CURRENT_ASSET: [{
        asset_amount: 1,
        asset_institution_name: 'Test Bank Name',
        asset_type: 'CHECKING',
      }],
      CURRENT_INCOME: [{
        income_type: 'PENSION_RETIREMENT',
        monthly_amount: 1,
      }],
      RESIDENCES: [{
        association_annual_amount: 1,
        city: 'City',
        current_residence_flag: 1,
        escro_flag: 1,
        monthly_amount: 1,
        annual_insurance_amount: 1,
        annual_tax_amount: 1,
        ownership_type: LivingSituation.OWN,
        postal_code: '48362',
        state: 'MI',
        street_address_1: 'TestAdd',
        street_address_2: 'TestAdd2',
      }],
      EMPLOYMENT: [{
        country: 'US',
        current_employeer_flag: 1,
        employment_type: 'RETIRED',
        job_start_date: '',
      }],
      birth_date: '2018-01-01 00:00:00',
      credit_score: '679',
      dec_alimony_childsupport_flag: 0,
      dec_bankruptcy_flag: 0,
      dec_borrowed_downpayment_flag: 0,
      dec_citizenship_flag: 1,
      dec_comaker_endorser_flag: 0,
      dec_home_owner_occupy_flag: 0,
      dec_home_owner_three_years_flag: 0,
      dec_loan_obligation_flag: 0,
      dec_outstanding_judgements_flag: 0,
      dec_party_to_lawsuit_flag: 0,
      dec_presently_delinquent_flag: 0,
      dec_property_foreclosed_flag: 0,
      dec_resident_alien_flag: 0,
      dependent_count: 0,
      email: 'test@lenderful.com',
      ethnicity_hispanic_cuban_flag: 0,
      ethnicity_hispanic_flag: 0,
      ethnicity_hispanic_mexican_flag: 0,
      ethnicity_hispanic_other_flag: 0,
      ethnicity_hispanic_puerto_rican_flag: 0,
      ethnicity_not_hispanic_flag: 0,
      ethnicity_not_provided_flag: 1,
      first_name: 'TestFirst',
      last_name: 'TestLast',
      marital_status: 'Married',
      middle_name: 'TestMiddle',
      phone: '(123) 456-7890',
      primary_borrower_flag: 1,
      race_asian_chinese_flag: 0,
      race_asian_filipino_flag: 0,
      race_asian_flag: 0,
      race_asian_indian_flag: 0,
      race_asian_japanese_flag: 0,
      race_asian_korean_flag: 0,
      race_asian_other_flag: 0,
      race_asian_vietnamese_flag: 0,
      race_black_flag: 0,
      race_islander_flag: 0,
      race_islander_guamanian_flag: 0,
      race_islander_native_hawaiian_flag: 0,
      race_islander_other_flag: 0,
      race_islander_samoan_flag: 0,
      race_native_flag: 0,
      race_not_provided_flag: 1,
      race_white_flag: 0,
      sex_female_flag: 0,
      sex_male_flag: 0,
      sex_not_provided_flag: 1,
    }],
    SUBJECT_PROPERTY: {
      association_annual_amount: 1,
      city: 'City',
      escro_flag: 1,
      estimated_amount: 250000,
      annual_insurance_amount: 1,
      annual_tax_amount: 1,
      postal_code: '48362',
      property_type: 'SINGLE_FAMILY',
      property_use: 'PRIMARY_RESIDENCE',
      purchase_amount: 250000,
      state: 'MI',
      street_address_1: 'Test Street',
      street_address_2: 'Test Street 2',
    },
    coborrower_on_loan_flag: 0,
    coborrower_on_title_flag: 0,
    down_payment_amount: 50000,
    loan_purpose: 'PURCHASE',
    plan_sell_flag: 1,
    requested_loan_amount: 200000,
    title_first_name: 'TestFirst',
    title_last_name: 'TestLast',
    title_middle_name: 'TestMiddle',
  },
  client: 'Flagstar Mortgage',
  client_meta: '{"app":{"ls_product":"longForm"}}',
  slug: 'flagstar',
  source: 'Pre-Approval Application',
};
export const emailMockResponse = {
  rates: [{
    applyUrl: '/v2/application/?rate=6&type=30%20Year%20Fixed%20Conventional&fees=-364',
    apr: 6.023,
    loanType: '30 Year Fixed Conventional',
    principalAndInterestPayment: 1199.1,
    pmiMonthlyPayment: 195,
    productFamily: 'CONVENTIONAL',
    productName: 'Conf 30 Yr  Fixed',
    term: 30,
    productType: 'FIXED',
    quoteFees: -364,
    rate: 6,
  }, {
    applyUrl: '/v2/application/?rate=4.875&type=15%20Year%20Fixed%20Conventional&fees=-248',
    apr: 4.905,
    loanType: '15 Year Fixed Conventional',
    principalAndInterestPayment: 1568.59,
    pmiMonthlyPayment: 195,
    productFamily: 'CONVENTIONAL',
    productName: 'Conf 15 Yr  Fixed',
    term: 15,
    productType: 'FIXED',
    quoteFees: -248,
    rate: 4.875,
  }, {
    applyUrl: '/v2/application/?rate=4.875&type=10%20Year%20Fixed%20Conventional&fees=310',
    apr: 4.919,
    loanType: '10 Year Fixed Conventional',
    principalAndInterestPayment: 2109.11,
    pmiMonthlyPayment: 195,
    productFamily: 'CONVENTIONAL',
    productName: 'Conf 10 Yr  Fixed',
    term: 10,
    productType: 'FIXED',
    quoteFees: 310,
    rate: 4.875,
  }, {
    applyUrl: '/v2/application/?rate=4.875&type=7%2F1%20ARM%20Conventional&fees=6118',
    apr: 5.205,
    armDetails: { margin: 2.25, initialCap: 5, periodicCap: 2, lifetimeCap: 5 },
    loanType: '7/1 ARM Conventional',
    principalAndInterestPayment: 1058.42,
    pmiMonthlyPayment: 195,
    productFamily: 'CONVENTIONAL',
    productName: 'Conf 30 Yr ARM 7/1',
    term: 30,
    productType: 'ARM',
    quoteFees: 6118,
    rate: 4.875,
  }],
  userData: {
    buying_stage: 'Signed Purchase Agreement',
    credit_score: '620 - 679',
    down_payment: 50000,
    email: 'test@lenderful.com',
    estimated_home_value: 250000,
    first_name: 'Test',
    flag_cash_out: 'No',
    flag_escro_taxes: 'Yes',
    last_name: 'Test',
    loan_amount: 200000,
    phone: '(123) 456-7890',
    property_type: 'Single Family',
    property_use: 'Primary Residence',
    purchase_price: 250000,
    slug: 'flagstar',
    type: 'PURCHASE',
    zip_code: '48362',
  },
};
export const equityMock = [
  {
    apr: 5.25,
    loanType: '10 Year Home Equity',
    principalAndInterestPayment: '536.46',
    pmiMonthlyPayment: 0,
    productFamily: 'Home Equity',
    productName: 'Home Equity 10',
    term: 10,
    productType: 'FIXED',
    rate: 5.25,
  },
  {
    apr: 5.25,
    loanType: '15 Year Home Equity',
    principalAndInterestPayment: '401.94',
    pmiMonthlyPayment: 0,
    productFamily: 'Home Equity',
    productName: 'Home Equity 15',
    term: 15,
    productType: 'FIXED',
    rate: 5.25,
  },
  {
    apr: 5.25,
    loanType: '30 Year Home Equity',
    principalAndInterestPayment: '276.10',
    pmiMonthlyPayment: 0,
    productFamily: 'Home Equity',
    productName: 'Home Equity 30',
    term: 30,
    productType: 'FIXED',
    rate: 5.25,
  },
  {
    apr: 3.5,
    loanType: '0.5 Year HELOC',
    principalAndInterestPayment: '145.83',
    pmiMonthlyPayment: 0,
    productFamily: 'HELOC',
    productName: 'HELOC 0.5',
    term: 0.5,
    productType: 'ARM',
    rate: 3.5,
  },
  {
    apr: 5.75,
    loanType: '10.5 Year HELOC',
    principalAndInterestPayment: '239.58',
    pmiMonthlyPayment: 0,
    productFamily: 'HELOC',
    productName: 'HELOC 10.5',
    term: 10.5,
    productType: 'ARM',
    rate: 5.75,
  },
  {
    apr: 5.75,
    loanType: '20 Year HELOC',
    principalAndInterestPayment: '351.04',
    pmiMonthlyPayment: 0,
    productFamily: 'HELOC',
    productName: 'HELOC 20',
    term: 20,
    productType: 'ARM',
    rate: 5.75,
  },
];
