import { SHARED } from 'app/models/questions/shared';
import { Currency } from 'app/components/FormFields/Currency';
import { IntegerAllowZero } from 'app/components/FormFields/IntegerAllowZero';
import { RadioField } from 'app/components/FormFields/RadioField';
import { Select } from 'app/components/FormFields/Select';
import { SingleDate } from 'app/components/FormFields/SingleDate';
import { SinglePastDate } from 'app/components/FormFields/SinglePastDate';
import { State } from 'app/components/FormFields/State';
import { Address } from 'app/components/FormFields/Address';
import { TextInput } from 'app/components/FormFields/TextInput';
import { ZipCode } from 'app/components/FormFields/ZipCode';
import { CurrencyAllowZero } from 'app/components/FormFields/CurrencyAllowZero';
import {
  EMPLOYMENT_TYPE_OPTIONS,
  YES_OR_NO_OPTIONS,
  COBORROWER_EMPLOYMENT_TYPE_OPTIONS,
  PROPERTY_TYPE_OPTIONS,
  PROPERTY_USAGE_OPTIONS, HOME_EQUITY_LOAN_PURPOSE_OPTIONS,
} from 'app/models/options/options';
import { AdditionalProperties } from 'app/components/FormFieldArrays/AdditionalProperties';
import { RetirementIncomeSources } from 'app/components/FormFieldArrays/RetirementIncomeSources';
import { IncomeSources } from 'app/components/FormFieldArrays/IncomeSources';
import { OtherIncomeSources } from 'app/components/FormFieldArrays/OtherIncomeSources';
import { MaximumLoanDisplay } from 'app/components/MaximumLoanDisplay';
import { Question } from 'app/models/types';
import { FieldNames } from 'app/models/fields/names';
import {
  hasCoBorrower,
  hasAddlProperties,
  isEmployee,
  isSelfEmployed,
  isLessThanTwoYrsAtJob,
  isRetired,
  isEmploymentOther,
  isCoBorrowerEmployee,
  isCoBorrowerSelfEmployed,
  isCoBorrowerRetired,
  isCoBorrowerEmploymentOther,
  isCoEmployee,
  isCoSelfEmployed,
  isCoLessThanTwoYrsAtJob, isHomeEquityLoanPurposeOther, hasShowAgesOfDependents, hasShowNumberOfDependents,
} from 'app/models/fields/conditionals';
import { CreditScoresRadio } from 'app/components/FormFields/CreditScoresRadio';
import { CollectConditionallyRequiredSsn } from 'app/components/FormFields/Ssn/CollectConditionallyRequiredSsn';
import { AgesFFAComponent } from 'app/components/FormFieldArrays/Dynamic/Ages';

/**
 * Takes in a field name and returns the corresponding home equity
 * question
 *
 * @param {string} name Field name
 * @returns {Question} Question with matching field name
 */
export const homeEquityLFQuestionByName = (name: string): Question => {
  return APPLICATION_HOME_EQUITY_QUESTIONS.find((question) => {
    return question.name === name;
  }) as Question;
};

/**
 * Takes in an array of field names and returns an array of questions
 *
 * @param {string[]} [names=[]] Field names
 * @returns {Question[]} Questions matching field names
 */
export const homeEquityLFQuestionsByName = (names: string[] = []): Question[] => {
  const questions: Question[] = [];
  for (const name of names) {
    questions.push(homeEquityLFQuestionByName(name));
  }
  return questions;
};

export const APPLICATION_HOME_EQUITY_QUESTIONS: Question[] = [
  {
    id       : 1001,
    component: RadioField,
    name     : FieldNames.homeEquityLoanPurpose,
    options  : HOME_EQUITY_LOAN_PURPOSE_OPTIONS,
    title    : 'What is the purpose of your home equity loan?',
  },
  {
    id       : 1002,
    component: TextInput,
    name     : FieldNames.homeEquityLoanPurposeOther,
    label    : 'Reason',
    showIf   : [isHomeEquityLoanPurposeOther],
  },
  {
    id: 1003,
    ...SHARED[FieldNames.firstName],
  },
  {
    id: 1004,
    ...SHARED[FieldNames.middleName],
  },
  {
    id: 1005,
    ...SHARED[FieldNames.lastName],
  },
  {
    id: 1006,
    ...SHARED[FieldNames.phone],
  },
  {
    id: 1007,
    ...SHARED[FieldNames.email],
  },
  {
    id: 1008,
    ...SHARED[FieldNames.dateOfBirth],
  },
  {
    id       : 1009,
    component: RadioField,
    name     : FieldNames.coBorrowerYN,
    options  : YES_OR_NO_OPTIONS,
    title    : 'Will you have a co-borrower?',
  },
  {
    id: 1010,
    ...SHARED[FieldNames.firstName],
    title : 'Tell us about the co-borrower',
    name  : FieldNames.coBorrowerFirstName,
    showIf: [hasCoBorrower],
  },
  {
    id: 1011,
    ...SHARED[FieldNames.middleName],
    name  : FieldNames.coBorrowerMiddleName,
    showIf: [hasCoBorrower],
  },
  {
    id: 1012,
    ...SHARED[FieldNames.lastName],
    name  : FieldNames.coBorrowerLastName,
    showIf: [hasCoBorrower],
  },
  {
    id: 1013,
    ...SHARED[FieldNames.email],
    name  : FieldNames.coBorrowerEmail,
    showIf: [hasCoBorrower],
  },
  {
    id: 1014,
    ...SHARED[FieldNames.dateOfBirth],
    name  : FieldNames.coBorrowerDOB,
    showIf: [hasCoBorrower],
  },
  {
    id: 1015,
    ...SHARED[FieldNames.creditScore],
    component    : CreditScoresRadio,
    title        : 'What is your estimated credit score?',
    fieldPopover : false,
    popover      : null,
  },
  {
    id: 1016,
    ...SHARED[FieldNames.maritalStatus],
  },
  {
    id       : 1017,
    component: IntegerAllowZero,
    name     : FieldNames.numOfDependent,
    title    : 'Number of dependents',
    showIf   : [hasShowNumberOfDependents],
  },
  {
    id       : 1094,
    component: AgesFFAComponent,
    name     : FieldNames.dependentsAges,
    title    : 'Do you have any Dependents?',
    showIf   : [hasShowAgesOfDependents],
  },
  {
    id       : 1018,
    component: TextInput,
    name     : FieldNames.propertyStreet,
    label    : 'Street Address',
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.propertyStreet,
    },
  },
  {
    id        : 1019,
    component : TextInput,
    name      : FieldNames.propertyStreet2,
    label     : 'Street Address 2',
    isOptional: true,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.propertyStreet2,
    },
  },
  {
    id       : 1020,
    component: TextInput,
    name     : FieldNames.propertyCity,
    label    : 'City',
  },
  {
    id       : 1021,
    component: State,
    name     : FieldNames.propertyState,
    label    : 'State',
  },
  {
    id       : 1022,
    component: ZipCode,
    name     : FieldNames.propertyZip,
    label    : 'Zip',
  },
  {
    id: 1023,
    ...SHARED[FieldNames.propertyType],
    component   : Select,
    options     : PROPERTY_TYPE_OPTIONS,
    fieldPopover: true,
  },
  {
    id: 1024,
    ...SHARED[FieldNames.propertyUsage],
    component   : Select,
    options     : PROPERTY_USAGE_OPTIONS,
    fieldPopover: true,
  },
  {
    id       : 1025,
    component: Currency,
    label    : 'Estimated Home Value',
    name     : FieldNames.homeValue,
    validator: {
      type : 'isGreaterThanField',
      param: FieldNames.loanAmount,
    },
  },
  {
    id       : 1026,
    component: CurrencyAllowZero,
    label    : 'Current Mortgage Balance',
    name     : FieldNames.mortgageBalance,
  },
  {
    id       : 1027,
    title    : 'How much would you like to borrow?',
    component: Currency,
    label    : 'Loan Amount',
    name     : FieldNames.loanAmount,
    validator: {
      type : 'loanAmountIsValid',
      param: FieldNames.maxLoanAmount,
    },
  },
  {
    id       : 1028,
    component: Currency,
    name     : FieldNames.livingYearTaxes,
    label    : 'Annual Taxes',
  },
  {
    id       : 1029,
    component: Currency,
    name     : FieldNames.livingYearInsure,
    label    : 'Annual Insurance',
  },
  {
    id        : 1030,
    component : Currency,
    isOptional: true,
    name      : FieldNames.livingYearAssociate,
    label     : 'Annual Association Fees',
  },
  {
    ...SHARED[FieldNames.hasAddlPropertiesYN],
    id    : 1031,
    title : 'Do you own additional properties?',
  },
  {
    id       : 1032,
    component: AdditionalProperties,
    name     : FieldNames.addlProperties,
    showIf   : [hasAddlProperties],
  },
  {
    id       : 1033,
    component: RadioField,
    title    : 'What is your income source?',
    name     : FieldNames.employmentType,
    options  : EMPLOYMENT_TYPE_OPTIONS,
  },
  {
    id       : 1034,
    component: TextInput,
    label    : 'Company Name',
    name     : FieldNames.employCompanyName,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1035,
    component: Address,
    label    : 'Street Address',
    name     : FieldNames.employStreet,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id        : 1036,
    component : Address,
    isOptional: true,
    label     : 'Street Address 2',
    name      : FieldNames.employStreet2,
    showIf    : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1037,
    component: Address,
    label    : 'City',
    name     : FieldNames.employCity,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1038,
    component: State,
    label    : 'State',
    name     : FieldNames.employState,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1039,
    component: ZipCode,
    label    : 'Zip Code',
    name     : FieldNames.employZip,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1040,
    component: SinglePastDate,
    label    : 'Start Date',
    name     : FieldNames.employStart,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1041,
    component: Currency,
    label    : 'Monthly Base Pay',
    name     : FieldNames.employBase,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id          : 1042,
    component   : Currency,
    fieldPopover: true,
    isOptional  : true,
    label       : 'Monthly Overtime',
    name        : FieldNames.employOvertime,
    showIf      : [isEmployee, isSelfEmployed],
    popover     :
      'Overtime can be included in your income if it has been consistent over a two-year period.',
    title: 'Additional income sources?',
  },
  {
    id          : 1043,
    component   : Currency,
    fieldPopover: true,
    isOptional  : true,
    label       : 'Monthly Commission',
    name        : FieldNames.employCommission,
    showIf      : [isEmployee, isSelfEmployed],
    popover     :
      'Commissions can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id          : 1044,
    component   : Currency,
    fieldPopover: true,
    isOptional  : true,
    label       : 'Monthly Bonus',
    name        : FieldNames.employBonus,
    showIf      : [isEmployee, isSelfEmployed],
    popover     :
      'Bonuses can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id       : 1045,
    component: TextInput,
    label    : 'Previous Employers Name',
    name     : FieldNames.employPrevName,
    title    : 'Previous employment information',
    showIf   : [isLessThanTwoYrsAtJob],
    popover  :
    'Lenders will require previous employer information if you\'ve been with this employer for less than 2 years.',
  },
  {
    id       : 1046,
    component: Address,
    label    : 'Street Address',
    name     : FieldNames.employPrevStreet,
    showIf   : [isLessThanTwoYrsAtJob],
  },
  {
    id        : 1047,
    component : Address,
    isOptional: true,
    label     : 'Street Address 2',
    name      : FieldNames.employPrevStreet2,
    showIf    : [isLessThanTwoYrsAtJob],
  },
  {
    id       : 1048,
    component: Address,
    label    : 'City',
    name     : FieldNames.employPrevCity,
    showIf   : [isLessThanTwoYrsAtJob],
  },
  {
    id       : 1049,
    component: State,
    label    : 'State',
    name     : FieldNames.employPrevState,
    showIf   : [isLessThanTwoYrsAtJob],
  },
  {
    id       : 1050,
    component: ZipCode,
    label    : 'Zip',
    name     : FieldNames.employPrevZip,
    showIf   : [isLessThanTwoYrsAtJob],
  },
  {
    id       : 1051,
    component: SinglePastDate,
    label    : 'Start Date',
    name     : FieldNames.employPrevStart,
    showIf   : [isLessThanTwoYrsAtJob],
  },
  {
    id       : 1052,
    component: SinglePastDate,
    label    : 'End Date',
    name     : FieldNames.employPrevEnd,
    showIf   : [isLessThanTwoYrsAtJob],
    validator: {
      type : 'isAfterStartDate',
      param: FieldNames.employPrevStart,
    },
  },
  {
    id       : 1053,
    component: RetirementIncomeSources,
    title    : 'Tell us about your retirement income',
    name     : FieldNames.retirementIncome,
    showIf   : [isRetired],
  },
  {
    id       : 1054,
    component: IncomeSources,
    name     : FieldNames.additionalIncome,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1055,
    component: MaximumLoanDisplay,
    label    : 'Maximum Loan Amount',
    name     : FieldNames.maxLoanAmount,
  },
  {
    id        : 1056,
    component : OtherIncomeSources,
    title     : 'Tell us about your other income',
    disclaimer: 'You are not obligated to disclose this information.',
    name      : FieldNames.otherIncome,
    showIf    : [isEmploymentOther],
    isOptional: true,
  },
  {
    id       : 1057,
    component: RadioField,
    title    : 'What is your co-borrower\'s income source?',
    name     : FieldNames.coBorrowerEmploymentType,
    options  : COBORROWER_EMPLOYMENT_TYPE_OPTIONS,
  },
  {
    id       : 1058,
    component: Currency,
    name     : FieldNames.coBorrowerEmployBase,
    label    : 'Monthly Base Pay',
    showIf   : [isCoBorrowerEmployee, isCoBorrowerSelfEmployed],
  },
  {
    id          : 1059,
    component   : Currency,
    fieldPopover: true,
    isOptional  : true,
    label       : 'Monthly Overtime',
    name        : FieldNames.coBorrowerEmployOvertime,
    showIf      : [isCoBorrowerEmployee, isCoBorrowerSelfEmployed],
    popover     :
      'Overtime can be included in your income if it has been consistent over a two-year period.',
    title: 'Additional income sources?',
  },
  {
    id          : 1060,
    component   : Currency,
    fieldPopover: true,
    isOptional  : true,
    label       : 'Monthly Commission',
    name        : FieldNames.coBorrowerEmployCommission,
    showIf      : [isCoBorrowerEmployee, isCoBorrowerSelfEmployed],
    popover     :
      'Commissions can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id          : 1061,
    component   : Currency,
    fieldPopover: true,
    isOptional  : true,
    label       : 'Monthly Bonus',
    name        : FieldNames.coBorrowerEmployBonus,
    showIf      : [isCoBorrowerEmployee, isCoBorrowerSelfEmployed],
    popover     :
      'Bonuses can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id          : 1062,
    component   : IncomeSources,
    name        : FieldNames.coBorrowerAdditionalIncome,
    fieldPopover: true,
    popover     : 'Income derived from alimony, child support, or separate maintenance payments need not be revealed if the applicant does not want the creditor to consider it in determining the applicant\'s creditworthiness.',
    showIf      : [isCoBorrowerEmployee, isCoBorrowerSelfEmployed],
  },
  {
    id       : 1063,
    component: RetirementIncomeSources,
    name     : FieldNames.coBorrowerRetirementIncome,
    showIf   : [isCoBorrowerRetired],
  },
  {
    id       : 1064,
    component: OtherIncomeSources,
    name     : FieldNames.coBorrowerOtherIncome,
    showIf   : [isCoBorrowerEmploymentOther],
  },
  {
    id: 1065,
    component: TextInput,
    label: "Company Name",
    name: FieldNames.coBorrowerEmployCompanyName,
    showIf: [isCoEmployee, isCoSelfEmployed],
  },
  {
    id: 1066,
    component: Address,
    label: "Street Address",
    name: FieldNames.coBorrowerEmployStreet,
    showIf: [isCoEmployee, isCoSelfEmployed],
  },
  {
    id: 1067,
    component: Address,
    isOptional: true,
    label: "Street Address 2",
    name: FieldNames.coBorrowerEmployStreet2,
    showIf: [isCoEmployee, isCoSelfEmployed],
  },
  {
    id: 1068,
    component: Address,
    label: "City",
    name: FieldNames.coBorrowerEmployCity,
    showIf: [isCoEmployee, isCoSelfEmployed],
  },
  {
    id: 1069,
    component: State,
    label: "State",
    name: FieldNames.coBorrowerEmployState,
    showIf: [isCoEmployee, isCoSelfEmployed],
  },
  {
    id: 1070,
    component: ZipCode,
    label: "Zip Code",
    name: FieldNames.coBorrowerEmployZip,
    showIf: [isCoEmployee, isCoSelfEmployed],
  },
  {
    id: 1071,
    component: SinglePastDate,
    label: "Start Date",
    name: FieldNames.coBorrowerEmployStart,
    showIf: [isCoEmployee, isCoSelfEmployed],
  },
  {
    id: 1072,
    component: Currency,
    label: "Monthly Base Pay",
    name: FieldNames.coBorrowerEmployBase,
    showIf: [isCoEmployee, isCoSelfEmployed],
  },
  {
    id: 1073,
    component: TextInput,
    label: "Previous Employers Name",
    name: FieldNames.coBorrowerEmployPrevName,
    title: "Previous employment information",
    showIf: [isCoLessThanTwoYrsAtJob],
    popover:
      "Lenders will require previous employer information if you've been with this employer for less than 2 years.",
  },
  {
    id: 1074,
    component: Address,
    label: "Street Address",
    name: FieldNames.coBorrowerEmployPrevStreet,
    showIf: [isCoLessThanTwoYrsAtJob],
  },
  {
    id: 1075,
    component: Address,
    isOptional: true,
    label: "Street Address 2",
    name: FieldNames.coBorrowerEmployPrevStreet2,
    showIf: [isCoLessThanTwoYrsAtJob],
  },
  {
    id: 1076,
    component: Address,
    label: "City",
    name: FieldNames.coBorrowerEmployPrevCity,
    showIf: [isCoLessThanTwoYrsAtJob],
  },
  {
    id: 1077,
    component: State,
    label: "State",
    name: FieldNames.coBorrowerEmployPrevState,
    showIf: [isCoLessThanTwoYrsAtJob],
  },
  {
    id: 1078,
    component: ZipCode,
    label: "Zip",
    name: FieldNames.coBorrowerEmployPrevZip,
    showIf: [isCoLessThanTwoYrsAtJob],
  },
  {
    id: 1079,
    component: SinglePastDate,
    label: "Start Date",
    name: FieldNames.coBorrowerEmployPrevStart,
    showIf: [isCoLessThanTwoYrsAtJob],
  },
  {
    id: 1080,
    component: SingleDate,
    label: "End Date",
    name: FieldNames.coBorrowerEmployPrevEnd,
    showIf: [isCoLessThanTwoYrsAtJob],
  },
  {
    id: 1081,
    component: Currency,
    fieldPopover: true,
    isOptional: true,
    label: "Monthly Overtime",
    name: FieldNames.coBorrowerEmployOvertime,
    showIf: [isCoEmployee, isCoSelfEmployed],
    popover:
      "Overtime can be included in your income if it has been consistent over a two-year period.",
    title: "Additional income sources?",
  },
  {
    id: 1082,
    component: Currency,
    fieldPopover: true,
    isOptional: true,
    label: "Monthly Commission",
    name: FieldNames.coBorrowerEmployCommission,
    showIf: [isCoEmployee, isCoSelfEmployed],
    popover:
      "Commissions can be included in your income if they have been consistent over a two-year period.",
  },
  {
    id: 1083,
    component: Currency,
    fieldPopover: true,
    isOptional: true,
    label: "Monthly Bonus",
    name: FieldNames.coBorrowerEmployBonus,
    showIf: [isCoEmployee, isCoSelfEmployed],
    popover:
      "Bonuses can be included in your income if they have been consistent over a two-year period.",
  },
  {
    id: 1084,
    component: IncomeSources,
    name: FieldNames.coBorrowerAdditionalIncome,
    showIf: [isCoEmployee, isCoSelfEmployed],
  },
  {
    id        : 1085,
    component : TextInput,
    label     : 'Job Title',
    isOptional: true,
    name      : FieldNames.employTitle,
    showIf    : [isEmployee, isSelfEmployed],
  },
  {
    id        : 1086,
    component : TextInput,
    label     : 'Job Title',
    isOptional: true,
    name      : FieldNames.coBorrowerEmployTitle,
    showIf    : [isCoEmployee, isCoSelfEmployed],
  },
  {
    id: 1087,
    ...SHARED[FieldNames.suffixName],
  },
  {
    id: 1088,
    ...SHARED[FieldNames.coBorrowerSuffixName],
  },
  {
    id: 1089,
    ...SHARED[FieldNames.employPrevTitle],
  },
  {
    id: 1090,
    ...SHARED[FieldNames.coBorrowerEmployPrevTitle],
  },
  {
    id: 1091,
    ...SHARED[FieldNames.citizenshipType],
  },
  {
    id: 1092,
    ...SHARED[FieldNames.coBorrowerCitizenshipType],
  },
  {
    id        : 1093,
    component : CollectConditionallyRequiredSsn,
    name      : FieldNames.ssnQuestionRequirement,
  },
];
