import { RadioField } from 'app/components/FormFields/RadioField';
import { ZipCode } from 'app/components/FormFields/ZipCode';
import {
  COBORROWER_EMPLOYMENT_TYPE_OPTIONS,
  EMPLOYMENT_TYPE_OPTIONS,
  LIVING_SITUATION_OPTIONS,
  YES_OR_NO_OPTIONS,
} from 'app/models/options/options';
import { Question } from 'app/models/types';
import { FieldNames } from 'app/models/fields/names';
import {
  coBorrowerHasDifferentAddress,
  hasCoBorrower,
  isCoLessThanTwoYrsAtJob,
  isLessThanTwoYrsAtJob,
  relativeHasDifferentAddress,
} from '../fields/conditionals';
import { Currency } from '../../components/FormFields/Currency';
import { INCOME_DISCLAIMER, SHARED } from 'app/models/questions/shared';
import { Address } from '../../components/FormFields/Address';
import { State } from '../../components/FormFields/State';
import { SinglePastDate } from '../../components/FormFields/SinglePastDate';
import { TextInput } from '../../components/FormFields/TextInput';
import { SingleDate } from '../../components/FormFields/SingleDate';
import { RetirementIncomeSources } from '../../components/FormFieldArrays/RetirementIncomeSources';
import { OtherIncomeSources } from '../../components/FormFieldArrays/OtherIncomeSources';
import {
  CREDIT_CARD_FINAL_PAGE_QUESTION,
} from '../sections/constants';
import { FormParagraphs } from 'app/models/paragraphs';
import { CreditCardRadio } from 'app/components/CreditCard/CreditCardRadio';
import { CreditCardConfirmation } from 'app/components/CreditCard/CreditCardConfirmation';
import { CollectConditionallyRequiredSsn } from 'app/components/FormFields/Ssn/CollectConditionallyRequiredSsn';

/**
 * Takes in a field name and returns the corresponding auto loan
 * question
 *
 * @param {string} name Field name
 * @returns {Question} Question with matching field name
 */
export const creditCardQuestionByName = (name: string): Question => {
  return CREDIT_CARD_QUESTIONS.find((question) => {
    return question.name === name;
  }) as Question;
};

/**
 * Takes in an array of field names and returns an array of questions
 *
 * @param {string[]} [names=[]] Field names
 * @returns {Question[]} Questions matching field names
 */
export const creditCardQuestionsByName = (names: string[] = []): Question[] => {
  const questions: Question[] = [];
  for (const name of names) {
    questions.push(creditCardQuestionByName(name));
  }
  return questions;
};

export const creditCardQuestionById = (id: number): Question => {
  return CREDIT_CARD_QUESTIONS.find((question) => question.id === id) as Question;
};

export const CREDIT_CARD_QUESTIONS: Question[] = [

  {
    id: 1001,
    ...SHARED[FieldNames.firstName],
    title: 'Enter your personal information',
  },
  {
    id: 1002,
    ...SHARED[FieldNames.middleName],
  },
  {
    id: 1003,
    ...SHARED[FieldNames.lastName],
  },
  {
    id: 1004,
    ...SHARED[FieldNames.suffixName],
  },
  {
    id: 1005,
    ...SHARED[FieldNames.mothersMaidenName],
  },
  {
    id: 1006,
    ...SHARED[FieldNames.email],
  },
  {
    id: 1007,
    ...SHARED[FieldNames.phone],
  },
  {
    id: 1008,
    ...SHARED[FieldNames.dateOfBirth],
  },
  {
    id           : 1009,
    component    : RadioField,
    name         : FieldNames.coBorrowerYN,
    options      : YES_OR_NO_OPTIONS,
    title        : 'not real but is essential and uses translations',
  },
  {
    ...SHARED[FieldNames.firstName],
    id           : 1010,
    name         : FieldNames.coBorrowerFirstName,
    showIf       : [hasCoBorrower],
    title        : 'not real but is essential and uses translations',
  },
  {
    ...SHARED[FieldNames.lastName],
    id           : 1011,
    name         : FieldNames.coBorrowerLastName,
    showIf       : [hasCoBorrower],
  },
  {
    ...SHARED[FieldNames.middleName],
    id           : 1012,
    name         : FieldNames.coBorrowerMiddleName,
    showIf       : [hasCoBorrower],
  },
  {
    ...SHARED[FieldNames.email],
    id           : 1013,
    isOptional   : true,
    name         : FieldNames.coBorrowerEmail,
    showIf       : [hasCoBorrower],
  },
  {
    ...SHARED[FieldNames.dateOfBirth],
    id           : 1014,
    name         : FieldNames.coBorrowerDOB,
    showIf       : [hasCoBorrower],
  },
  {
    id           : 1015,
    ...SHARED[FieldNames.coBorrowerSuffixName],
  },
  {
    id           : 1016,
    component    : RadioField,
    name         : FieldNames.livingRentOrOwn,
    options      : LIVING_SITUATION_OPTIONS,
  },
  {
    id           : 1017,
    component    : Address,
    label        : 'Street Address',
    name         : FieldNames.livingPropertyStreet,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPropertyStreet,
    },
  },
  {
    id           : 1018,
    component    : Address,
    isOptional   : true,
    label        : 'Street Address 2',
    name         : FieldNames.livingPropertyStreet2,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPropertyStreet2,
    },
  },
  {
    id           : 1019,
    component    : Address,
    label        : 'City',
    name         : FieldNames.livingPropertyCity,
  },
  {
    id           : 1020,
    component    : State,
    label        : 'State',
    name         : FieldNames.livingPropertyState,
  },
  {
    ...SHARED[FieldNames.propertyZip],
    id           : 1021,
    name         : FieldNames.livingPropertyZip,
    hideTitle : true,
  },
  {
    id           : 1028,
    component    : SinglePastDate,
    label        : 'Occupancy Start Date',
    name         : FieldNames.livingOccupancyStart,
  },
  {
    id           : 1029,
    component    : RadioField,
    name         : FieldNames.coBorrowerSameAddress,
    options      : YES_OR_NO_OPTIONS,
  },
  {
    id           : 1030,
    component    : Address,
    label        : 'Street Address',
    name         : FieldNames.coBorrowerLivingPropertyStreet,
    title        : `Co-applicant's current address?`,
    showIf       : [coBorrowerHasDifferentAddress],
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.coBorrowerLivingPropertyStreet,
    },
  },
  {
    id           : 1031,
    component    : Address,
    isOptional   : true,
    label        : 'Street Address 2',
    name         : FieldNames.coBorrowerLivingPropertyStreet2,
    showIf       : [coBorrowerHasDifferentAddress],
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.coBorrowerLivingPropertyStreet2,
    },
  },
  {
    id           : 1032,
    component    : Address,
    label        : 'City',
    name         : FieldNames.coBorrowerLivingPropertyCity,
    showIf       : [coBorrowerHasDifferentAddress],
  },
  {
    id           : 1033,
    component    : State,
    label        : 'State',
    name         : FieldNames.coBorrowerLivingPropertyState,
    showIf       : [coBorrowerHasDifferentAddress],
  },
  {
    id           : 1034,
    component    : ZipCode,
    label        : 'Zip Code',
    name         : FieldNames.coBorrowerLivingPropertyZip,
    showIf       : [coBorrowerHasDifferentAddress],
  },
  {
    id           : 1035,
    component    : RadioField,
    name         : FieldNames.employmentType,
    options      : EMPLOYMENT_TYPE_OPTIONS,
    disclaimer   : INCOME_DISCLAIMER,
  },
  {
    id           : 1036,
    component    : TextInput,
    label        : 'Company Name',
    name         : FieldNames.employCompanyName,
  },
  {
    id           : 1037,
    component    : TextInput,
    label        : 'Job Title',
    isOptional   : true,
    name         : FieldNames.employTitle,
  },
  {
    id           : 1038,
    component    : SinglePastDate,
    label        : 'Start Date',
    name         : FieldNames.employStart,
  },
  {
    id           : 1039,
    component    : Currency,
    label        : 'Monthly Gross Pay',
    name         : FieldNames.employBase,
    fieldPopover : true,
    popover      : 'Monthly gross pay can include base pay, OT, commission, bonuses and similar other pay.',
  },
  {
    id           : 1040,
    showIf       : [isLessThanTwoYrsAtJob],
    component    : TextInput,
    label        : 'Previous Employers Name',
    name         : FieldNames.employPrevName,
    title        : 'Previous employment information',
    popover      : `Lenders will require previous employer information if you've been with this employer for less than 2 years.`,
  },
  {
    id           : 1041,
    showIf       : [isLessThanTwoYrsAtJob],
    component    : SinglePastDate,
    label        : 'Start Date',
    name         : FieldNames.employPrevStart,
  },
  {
    id           : 1042,
    showIf       : [isLessThanTwoYrsAtJob],
    component    : SingleDate,
    label        : 'End Date',
    name         : FieldNames.employPrevEnd,
  },
  {
    id           : 1043,
    component    : RetirementIncomeSources,
    title        : 'Tell us about your income',
    name         : FieldNames.retirementIncome,
  },
  {
    id           : 1044,
    component    : OtherIncomeSources,
    title        : 'Tell us about your income',
    name         : FieldNames.otherIncome,
  },
  {
    id           : 1046,
    component    : RadioField,
    name         : FieldNames.coBorrowerEmploymentType,
    options      : COBORROWER_EMPLOYMENT_TYPE_OPTIONS,
  },
  {
    id           : 1047,
    component    : TextInput,
    label        : 'Company Name',
    name         : FieldNames.coBorrowerEmployCompanyName,
  },
  {
    id           : 1048,
    component    : TextInput,
    label        : 'Job Title',
    isOptional   : true,
    name         : FieldNames.coBorrowerEmployTitle,
  },
  {
    id           : 1049,
    component    : SinglePastDate,
    label        : 'Start Date',
    name         : FieldNames.coBorrowerEmployStart,
  },
  {
    id           : 1050,
    component    : Currency,
    label        : 'Monthly Gross Pay',
    name         : FieldNames.coBorrowerEmployBase,
    fieldPopover : true,
    popover      : 'Monthly gross pay can include base pay, OT, commission, bonuses and similar other pay.',
  },
  {
    id           : 1051,
    showIf       : [isCoLessThanTwoYrsAtJob],
    component    : TextInput,
    label        : 'Previous Employers Name',
    name         : FieldNames.coBorrowerEmployPrevName,
    title        : 'Previous employment information',
    popover      : `Lenders will require previous employer information if you've been with this employer for less than 2 years.`,
  },
  {
    id           : 1052,
    showIf       : [isCoLessThanTwoYrsAtJob],
    component    : SinglePastDate,
    label        : 'Start Date',
    name         : FieldNames.coBorrowerEmployPrevStart,
  },
  {
    id           : 1053,
    showIf       : [isCoLessThanTwoYrsAtJob],
    component    : SingleDate,
    label        : 'End Date',
    name         : FieldNames.coBorrowerEmployPrevEnd,
  },
  {
    id           : 1054,
    title        : 'Tell us about your income',
    component    : RetirementIncomeSources,
    name         : FieldNames.coBorrowerRetirementIncome,
  },
  {
    id           : 1055,
    title        : 'Tell us about your income',
    component    : OtherIncomeSources,
    name         : FieldNames.coBorrowerOtherIncome,
  },
  {
    id          : CREDIT_CARD_FINAL_PAGE_QUESTION,
    component   : CreditCardConfirmation,
    name        : 'FinalPage',
  },
  {
    id           : 1056,
    component    : CreditCardRadio,
    name         : FieldNames.creditCardType,
    paragraph    : FormParagraphs.creditCardVerbiage,
  },
  {
    ...SHARED[FieldNames.firstName],
    id           : 1057,
    name         : FieldNames.relativeFirstName,
    title        : 'not real but is essential and uses translations',
  },
  {
    ...SHARED[FieldNames.lastName],
    id           : 1058,
    name         : FieldNames.relativeLastName,
  },
  {
    ...SHARED[FieldNames.middleName],
    id           : 1059,
    name         : FieldNames.relativeMiddleName,
  },
  {
    ...SHARED[FieldNames.email],
    id         : 1060,
    isOptional : true,
    name       : FieldNames.relativeEmail,
  },
  {
    ...SHARED[FieldNames.dateOfBirth],
    id           : 1061,
    name         : FieldNames.relativeDOB,
  },
  {
    ...SHARED[FieldNames.suffixName],
    id           : 1062,
    name         : FieldNames.relativeSuffixName,
  },
  {
    id           : 1063,
    component    : RadioField,
    name         : FieldNames.relativeSameAddress,
    options      : YES_OR_NO_OPTIONS,
  },
  {
    id           : 1064,
    component    : Address,
    label        : 'Street Address',
    name         : FieldNames.relativeLivingPropertyStreet,
    title        : `Nearest Relative's current address?`,
    showIf       : [relativeHasDifferentAddress],
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.relativeLivingPropertyStreet,
    },
  },
  {
    id           : 1065,
    component    : Address,
    isOptional   : true,
    label        : 'Street Address 2',
    name         : FieldNames.relativeLivingPropertyStreet2,
    showIf       : [relativeHasDifferentAddress],
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.relativeLivingPropertyStreet2,
    },
  },
  {
    id           : 1066,
    component    : Address,
    label        : 'City',
    name         : FieldNames.relativeLivingPropertyCity,
    showIf       : [relativeHasDifferentAddress],
  },
  {
    id           : 1067,
    component    : State,
    label        : 'State',
    name         : FieldNames.relativeLivingPropertyState,
    showIf       : [relativeHasDifferentAddress],
  },
  {
    id           : 1068,
    component    : ZipCode,
    label        : 'Zip Code',
    name         : FieldNames.relativeLivingPropertyZip,
    showIf       : [relativeHasDifferentAddress],
  },
  {
    ...SHARED[FieldNames.phone],
    id     : 1069,
    name   : FieldNames.coBorrowerPhone,
    showIf : [hasCoBorrower],
  },
  {
    ...SHARED[FieldNames.phone],
    id   : 1070,
    name : FieldNames.relativePhone,
  },
  {
    ...SHARED[FieldNames.phone],
    id   : 1071,
    name : FieldNames.employPhone,
  },
  {
    id        : 1072,
    component : CollectConditionallyRequiredSsn,
    name      : FieldNames.ssnQuestionRequirement,
  },
  {
    id           : 1073,
    component    : Currency,
    label        : 'Credit Card Limit',
    name         : FieldNames.creditCardLimit,
    validator : {
      type: 'isBetweenValues',
      param: [500, 5000],
    },
  },
];
