import { FormName } from 'app/models/options/enums';

export enum LoanPurpose {
  CONSTRUCTION         = 'CONSTRUCTION',
  REFINANCE            = 'REFINANCE',
  MONTHLY              = 'REFINANCE',
  TERMS                = 'REFINANCE',
  CASHOUT              = 'CASHOUT',
  CONSOLIDATE          = 'CONSOLIDATE',
  LAND_PURCHASE        = 'LAND PURCHASE',
  PURCHASE             = 'PURCHASE',
  HELOC                = 'HELOC',
  PERSONAL_LOAN        = 'UNSECURED',
  AUTO_LOAN            = 'AUTO',
  REVERSE              = 'REVERSE',
  OVERDRAFT_PROTECTION = 'UNSECURED', //@TODO LP-6272
}

export interface RatesRequest {
  customerID                   : string;
  escrowsWaived                : boolean;
  creditReportId?              : string;
  fico?                        : string;
  loanAmount                   : number;
  loanPurpose                  : string;
  propertyAppraisedValue       : number;
  propertyType                 : string;
  propertyUsage                : string;
  propertyZip                  : string;
}

export interface Income {
  amount: number;
  type  : string;
}

export interface Liability {
  amount: number;
  type  : string;
}

export interface PrequalRatesRequest extends RatesRequest {
  annualTaxes       : number;
  annualInsurance   : number;
  annualHOA         : number;
  monthlyIncome     : Income[];
  monthlyLiabilities: Liability[];
}

export type RatesPropertyUsage = 'PRIMARY_RESIDENCE' | 'SECONDARY_OR_VACATION' | 'INVESTMENT_OR_RENTAL';
export type RatesPropertyType = 'SINGLE_FAMILY' | 'CONDO' | 'MODULAR' | 'MULTI_DWELLING' | 'MANUFACTURED_HOME_ON_FOUNDATION';

export enum ConsumerLoanRequestType {
  AUTO                 = 'AUTO',
  CREDIT_BOOSTER       = 'CREDIT_BOOSTER',
  OVERDRAFT_PROTECTION = 'OVERDRAFT_PROTECTION',
  PERSONAL             = 'PERSONAL',
}

export interface ConsumerLoanRatesRequest {
  creditReportId?   : number;
  fico?             : string;
  hasUniqueProgram? : boolean;
  loanAmount        : number;
  type              : ConsumerLoanRequestType;
  vehiclePrice?     : number;
  vehicleYear?      : number;
  zipCode           : string;
}

export interface LoanProgramRatesResponse extends Array<LoanProgram> {}

export interface LoanProgram {
  loanAmount                  : number;
  principalAndInterestPayment : number;
  productName                 : string;
  productType                 : string;
  rate                        : number;
  term                        : number;
}

export interface ConsumerLoanLimitsRequest {
  fico : string;
  type : ConsumerLoanRequestType;
}

export interface ConsumerLoanLimitsResponse {
  maxLoanAmount: number;
  minLoanAmount: number;
}

export interface ConsumerLoanDebtToIncomeRequest  {
  creditReportId     : string;
  formName           : FormName;
  monthlyLiabilities : number;
  totalIncome        : number;
  type               : ConsumerLoanRequestType;
}

export interface ConsumerLoanDebtToIncomeResponse {
  isOverLimit: boolean;
}

export interface ReverseMortgageCalcsRequest  {
  ageOfYoungest      : number;
  estimatedHomeValue : number;
  expectedLumpSum    : number;
  mortgageBalance    : number;
  propertyState      : string;
  term               : number;
}
export interface ReverseMortgageCalcsResponse {
  initialMortgageInsurance : number;
  maxClaimAmount           : number;
  maxLumpSum               : number;
  netPrincipalLimit        : number;
  tenurePayment            : number;
  termPayment              : number;
  totalUpfrontCosts        : number;
}
